import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import {
  FlagMessagePayload,
  MarkAsReadPayload,
  Message,
  MessageCategorizationPayload,
} from '../../../../models/Message';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import { AdvancedMailSearchDto } from '../../../../models/MailSearch';
import {
  FETCH_MAIL_SEARCH_PROJECT_COMMIT,
  FETCH_MAIL_SEARCH_PROJECT_REQUEST,
  FETCH_MAIL_SEARCH_PROJECT_ROLLBACK,
} from '../../actions/projects/search';
import { distinct } from '../../../../util';
import {
  CATEGORIZED_MESSAGE_PROJECT,
  COPY_MAIL_TO_PROJECT_PROJECT_COMMIT,
  DELETE_LOCAL_MESSAGE_PROJECT,
  DELETE_MESSAGES_PROJECT_COMMIT,
  DELETE_MESSAGES_PROJECT_REQUEST,
  DELETE_MESSAGES_PROJECT_ROLLBACK,
  FETCH_MESSAGE_PROJECT_DECODE_MIME_COMMIT,
  FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT,
  FETCH_MESSAGE_PROJECT_INLINE_IMAGE_REQUEST,
  FETCH_MESSAGE_PROJECT_INLINE_IMAGE_ROLLBACK,
  FLAG_MESSAGE_PROJECT_REQUEST,
  FLAG_MESSAGE_PROJECT_ROLLBACK,
  MARK_AS_READ_REQUEST,
  MARK_AS_READ_ROLLBACK,
  MOVE_MESSAGE_PROJECT_COMMIT,
  MOVE_MESSAGE_PROJECT_REQUEST,
  MOVE_MESSAGE_PROJECT_ROLLBACK,
  SOFTDELETE_MESSAGES_PROJECT_REQUEST,
  SOFTDELETE_MESSAGES_PROJECT_ROLLBACK,
  UPDATE_MESSAGE_PROJECT,
} from '../../actions/projects/messages';
import {
  WS_EMAIL_PROJECT_DELETED,
  WS_EMAIL_PROJECT_UPDATED,
} from '../../actions/ws';
import {
  DELETE_MAIL_SEARCH_RESULTS_PROJECT,
  SAVE_LAST_SEARCH_MAIL_PROJECT,
  SET_CURRENT_MAIL_SEARCH_PROJECT,
} from '../../actions/actionControllers/searchActionController';
import { DELETE_LOCAL_MESSAGES } from '../../actions/actionControllers/messageActionController';
import { WS_RECONNECT } from '../../../../sagas/watchWebsocketReconnect';
import { SAGA_REBUILD } from '../../../../util/sagas';
import equals from 'deep-equal';

export interface MailSearchState {
  byId: ByIdState;
  ids: IdsState;
  nextLink: NextLinkState;
  lastSearchTermMail: SearchTermState;
  currentSearchTerm: SearchTermState;
  currentSearchMailFolderIds: CurrentMailfolderIdState;
  meta: MetaState;
}

export const initialState: MailSearchState = {
  byId: {},
  ids: {},
  nextLink: {},
  lastSearchTermMail: {},
  currentSearchTerm: {},
  currentSearchMailFolderIds: {},
  meta: {
    isFetching: false,
    hasError: false,
    isSingleMessageFetching: false,
    fetchingProjectIds: {},
  },
};

interface ByIdState {
  [messageId: string]: Message;
}

const byId: Reducer<ByIdState, any> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_PROJECT_COMMIT: {
      const { payload } = action;
      return (payload.messages as Array<Message>).reduce(
        (map: ByIdState | {}, item) => {
          map[item.id] = item;
          return map;
        },
        state
      );
    }

    case CATEGORIZED_MESSAGE_PROJECT: {
      const { payload } = action;
      const { messages, isDelete } = payload as MessageCategorizationPayload;
      return messages.reduce(
        (map, { messageId, categories }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              categories: isDelete
                ? (map[messageId]?.categories ?? []).filter(
                    (category) => !categories.includes(category)
                  )
                : distinct([
                    ...(map[messageId]?.categories ?? []),
                    ...categories,
                  ]),
            };
          }
          return map;
        },
        { ...state }
      );
    }

    case MARK_AS_READ_REQUEST: {
      const { payload } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        (map, { messageId, isRead }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case MARK_AS_READ_ROLLBACK: {
      const {
        meta: { payload },
      } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        (map, { messageId, isRead }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead: !isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }

    case FLAG_MESSAGE_PROJECT_REQUEST: {
      const { payload } = action;
      const changes = payload as FlagMessagePayload;
      return changes.reduce(
        (map, { messageId, flag }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case FLAG_MESSAGE_PROJECT_ROLLBACK: {
      const {
        meta: { originalPayload },
      } = action;
      const changes = originalPayload as FlagMessagePayload;
      return changes.reduce(
        (map, { messageId, flag }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }

    case UPDATE_MESSAGE_PROJECT: {
      const {
        payload: { messageUpdate },
        meta: { messageId },
      }: {
        payload: { messageUpdate: Partial<Message> };
        meta: { messageId: MessageId };
      } = action;
      if (!state[messageId]) {
        return state;
      }
      return {
        ...state,
        [messageId]: {
          ...(state[messageId] ?? {}),
          ...messageUpdate,
        },
      };
    }

    case WS_EMAIL_PROJECT_UPDATED: {
      const {
        payload: { message },
      }: { payload: { message: Message } } = action;
      if (state[message.id]) {
        return {
          ...state,
          [message.id]: message,
        };
      }
      return state;
    }

    case DELETE_LOCAL_MESSAGE_PROJECT: {
      const {
        meta: { messageId },
      } = action;
      if (state[messageId]) {
        const { [messageId]: _, ...rest } = state;
        return { ...rest };
      }
      return state;
    }

    case SOFTDELETE_MESSAGES_PROJECT_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      return ((messageIds as string[]) ?? []).reduce((map, id) => {
        const { [id]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }
    case SOFTDELETE_MESSAGES_PROJECT_ROLLBACK: {
      const {
        meta: { rollbackMessages },
      } = action;
      return ((rollbackMessages as Message[]) ?? []).reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, state);
    }

    case WS_EMAIL_PROJECT_DELETED:
    case DELETE_MESSAGES_PROJECT_COMMIT: {
      const {
        meta: { messageIds },
      } = action;
      return (messageIds as string[]).reduce((map, id) => {
        const { [id]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }

    case FETCH_MESSAGE_PROJECT_DECODE_MIME_COMMIT:
    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT: {
      const {
        payload,
        meta: { messageId },
      } = action;
      if (state[messageId]) {
        return {
          ...state,
          [messageId]: payload,
        };
      }
      return state;
    }

    case MOVE_MESSAGE_PROJECT_COMMIT: {
      const {
        meta: { messageIds },
      } = action;
      return Object.keys(state).reduce((map, messageId) => {
        if (messageIds.includes(messageId)) {
          return map;
        }
        return {
          ...map,
          [messageId]: state[messageId],
        };
      }, {});
    }

    case COPY_MAIL_TO_PROJECT_PROJECT_COMMIT: {
      const {
        meta: { deleteMail, messages },
      } = action;
      if (deleteMail) {
        const messageIdsToDelete = messages.map((message) => message.id);
        return {
          ...Object.keys(state).reduce((map, messageId) => {
            if (messageIdsToDelete.includes(messageId)) {
              return map;
            }
            return {
              ...map,
              [messageId]: state[messageId],
            };
          }, {}),
        };
      }
      return state;
    }

    case SAGA_REBUILD:
    case WS_RECONNECT:
    case SET_CURRENT_MAIL_SEARCH_PROJECT:
    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_PROJECT:
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default:
      return state;
  }
};

interface IdsState {
  [projectId: string]: MessageId[];
}

const ids: Reducer<IdsState, any> = (state = initialState.ids, action) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_PROJECT_COMMIT: {
      const {
        payload,
        meta: { projectId, nextLink },
      } = action;
      return {
        ...state,
        [projectId]: distinct(
          !(nextLink === null || nextLink === undefined)
            ? state[projectId]
              ? [
                  ...state[projectId],
                  ...(payload.messages as Array<Message>).map(
                    (item) => item.id
                  ),
                ]
              : (payload.messages as Array<Message>).map((item) => item.id)
            : (payload.messages as Array<Message>).map((item) => item.id)
        ),
      };
    }

    case WS_EMAIL_PROJECT_DELETED:
    case SOFTDELETE_MESSAGES_PROJECT_REQUEST:
    case DELETE_MESSAGES_PROJECT_REQUEST: {
      const {
        payload: { messageIds },
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: (state[projectId] ?? [])?.filter(
          (id) => !messageIds.includes(id)
        ),
      };
    }

    case SOFTDELETE_MESSAGES_PROJECT_ROLLBACK:
    case DELETE_MESSAGES_PROJECT_ROLLBACK: {
      const {
        meta: { projectId, messageIds },
      } = action;
      return {
        ...state,
        [projectId]: [...(state[projectId] ?? []), ...messageIds],
      };
    }

    case MOVE_MESSAGE_PROJECT_REQUEST: {
      const {
        meta: { projectId, messageIds },
      } = action;

      return {
        ...state,
        [projectId]: (state[projectId] ?? [])?.filter(
          (id) => !messageIds.includes(id)
        ),
      };
    }

    case MOVE_MESSAGE_PROJECT_ROLLBACK: {
      const {
        meta: { projectId, messageIds },
      } = action;

      return {
        ...state,
        [projectId]: distinct([...(state[projectId] ?? []), ...messageIds]),
      };
    }

    case DELETE_LOCAL_MESSAGE_PROJECT: {
      const {
        meta: { projectId, messageId },
      } = action;
      return {
        ...state,
        [projectId]: (state[projectId] ?? [])?.filter((id) => id !== messageId),
      };
    }

    case COPY_MAIL_TO_PROJECT_PROJECT_COMMIT: {
      const {
        meta: { deleteMail, messages, projectId },
      } = action;
      if (deleteMail && state[projectId]) {
        const messageIdsToDelete = messages.map((message) => message.id);
        return {
          ...state,
          [projectId]: state[projectId].filter(
            (messageId) => !messageIdsToDelete.includes(messageId)
          ),
        };
      }
      return state;
    }

    case SAGA_REBUILD:
    case WS_RECONNECT:
    case SET_CURRENT_MAIL_SEARCH_PROJECT:
    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_PROJECT:
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default:
      return state;
  }
};

interface NextLinkState {
  [projectId: ProjectId]: {
    [mailFolderId: MailFolderId]: string;
  };
}

const nextLink: Reducer<NextLinkState, any> = (
  state = initialState.nextLink,
  action
) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_PROJECT_COMMIT: {
      const {
        payload,
        meta: { projectId, mailFolderId },
      } = action;
      const _mailFolderId = mailFolderId ?? 'allFolders';
      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [_mailFolderId]: payload.nextLink ?? '',
        },
      };
    }

    case SET_CURRENT_MAIL_SEARCH_PROJECT: {
      const { projectId, mailFolderId } = action;
      const nextLink = state[projectId]?.[mailFolderId];
      if (!(nextLink === undefined || nextLink === null)) {
        const { [projectId]: projectState, ...rest } = state;
        if (!projectState) {
          return rest;
        }
        const { [mailFolderId]: _, ...projectRest } = projectState;
        return {
          ...rest,
          [projectId]: projectRest,
        };
      }
      return state;
    }

    case SAGA_REBUILD:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_PROJECT:
    case CLEAR_PRIO_CACHE: {
      return initialState.nextLink;
    }
    default:
      return state;
  }
};

interface SearchTermState {
  [projectId: string]: AdvancedMailSearchDto | string;
}

const lastSearchTermMail: Reducer<SearchTermState, any> = (
  state = initialState.lastSearchTermMail,
  action
) => {
  switch (action.type) {
    case SAVE_LAST_SEARCH_MAIL_PROJECT: {
      const { searchTerm, projectId } = action;
      return {
        ...state,
        [projectId]: searchTerm,
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialState.lastSearchTermMail;
    }
    default:
      return state;
  }
};

const currentSearchTerm: Reducer<SearchTermState, any> = (
  state = initialState.currentSearchTerm,
  action
) => {
  switch (action.type) {
    case SET_CURRENT_MAIL_SEARCH_PROJECT: {
      const { searchTerm, projectId } = action;
      if (equals(state[projectId], searchTerm)) {
        return state;
      }
      return {
        ...state,
        [projectId]: searchTerm,
      };
    }

    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_PROJECT:
    case CLEAR_PRIO_CACHE: {
      return initialState.currentSearchTerm;
    }
    default:
      return state;
  }
};

interface CurrentMailfolderIdState {
  [projecId: ProjectId]: MailFolderId | 'allFolders';
}

const currentSearchMailFolderIds: Reducer<CurrentMailfolderIdState, any> = (
  state = initialState.currentSearchMailFolderIds,
  action
) => {
  switch (action.type) {
    case SET_CURRENT_MAIL_SEARCH_PROJECT: {
      const { mailFolderId, projectId, searchTerm } = action;
      const _mailFolderId = searchTerm ? mailFolderId ?? null : null;
      if (state[projectId] === _mailFolderId) {
        return state;
      }
      return {
        ...state,
        [projectId]: _mailFolderId ?? null,
      };
    }

    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_PROJECT:
    case CLEAR_PRIO_CACHE: {
      return initialState.currentSearchMailFolderIds;
    }
    default:
      return state;
  }
};

interface MetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
  isSingleMessageFetching?: boolean;
  fetchingProjectIds: { [projectId: ProjectId]: MailFolderId[] };
}

const meta: Reducer<MetaState, any> = (state = initialState.meta, action) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_PROJECT_REQUEST: {
      const {
        meta: { projectId, mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: true,
        hasError: false,
        fetchingProjectIds: {
          ...state.fetchingProjectIds,
          [projectId]: [
            ...(state.fetchingProjectIds[projectId] ?? []),
            mailFolderId,
          ],
        },
      };
    }
    case FETCH_MAIL_SEARCH_PROJECT_COMMIT: {
      const {
        meta: { projectId, mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: false,
        hasError: false,
        fetchingProjectIds: {
          ...state.fetchingProjectIds,
          [projectId]: (state.fetchingProjectIds[projectId] ?? []).filter(
            (id) => id !== mailFolderId
          ),
        },
      };
    }
    case FETCH_MAIL_SEARCH_PROJECT_ROLLBACK: {
      const {
        meta: { projectId, mailFolderId },
      } = action;
      return {
        isFetching: false,
        hasError: true,
        errorMessage: 'mail:errorMessages.searchMessages.fetchMessagesError',
        fetchingProjectIds: {
          ...state.fetchingProjectIds,
          [projectId]: (state.fetchingProjectIds[projectId] ?? []).filter(
            (id) => id !== mailFolderId
          ),
        },
      };
    }

    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_REQUEST: {
      return {
        ...state,
        isSingleMessageFetching: true,
      };
    }

    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT: {
      return {
        ...state,
        isSingleMessageFetching: false,
      };
    }

    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_ROLLBACK: {
      return {
        ...state,
        isSingleMessageFetching: false,
        hasError: true,
        errorMessage: 'mail:errorMessages.messages.fetchError',
      };
    }

    case SAGA_REBUILD:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_PROJECT:
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default:
      return state;
  }
};

export default combineReducers<MailSearchState>({
  byId,
  ids,
  nextLink,
  lastSearchTermMail,
  currentSearchTerm,
  currentSearchMailFolderIds,
  meta,
});

export const getIsFetching: (
  state: MailSearchState,
  projectId: ProjectId,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) => {
  if (projectId === 'favorites') {
    return state.meta.isFetching;
  }
  if (mailFolderId) {
    return state.meta.fetchingProjectIds[projectId]?.includes(mailFolderId);
  }
  return (state.meta.fetchingProjectIds[projectId] ?? []).length > 0;
};
export const getHasError: (state: MailSearchState) => boolean = (state) => {
  return state.meta.hasError;
};
export const getErrorMessage: (state: MailSearchState) => string = (state) => {
  return state.meta.errorMessage;
};

export const getMessage: (
  state: MailSearchState,
  messageId: MessageId
) => Message = (state, messageId) => state.byId[messageId];
export const getMessages: (
  state: MailSearchState,
  projectId: ProjectId
) => Message[] = (state, projectId) =>
  state.ids[projectId]
    ?.map((id) => state.byId[id])
    ?.filter((message) => !!message)
    ?.sort((a: Message, b: Message) => {
      return Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime);
    });

export const getMessagesById: (state: MailSearchState) => ByIdState = (state) =>
  state.byId;

export const getMessageIds: (
  state: MailSearchState,
  projectId: ProjectId
) => MessageId[] = (state, projectId) => state.ids[projectId];

export const getMessageIdsState: (state: MailSearchState) => IdsState = (
  state
) => state.ids;

export const getNextLink: (
  state: MailSearchState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => string = (state, projectId, mailFolderId) =>
  state.nextLink[projectId]?.[mailFolderId];

export const getlastSearchTermMail: (
  state: MailSearchState,
  projectId: ProjectId
) => AdvancedMailSearchDto | string = (state, projectId) =>
  state.lastSearchTermMail[projectId];

export const getCurrentSearchTerm: (
  state: MailSearchState,
  projectId: ProjectId
) => AdvancedMailSearchDto | string = (state, projectId) =>
  state.currentSearchTerm[projectId] ?? null;

export const getCurrentSearchTermState: (
  state: MailSearchState
) => SearchTermState = (state) => state.currentSearchTerm;

export const getCurrentSearchMailFolderId: (
  state: MailSearchState,
  projectId: ProjectId
) => MailFolderId = (state, projectId) =>
  state.currentSearchMailFolderIds[projectId];

export const getCurrentSearchMailFolderIdState: (
  state: MailSearchState
) => CurrentMailfolderIdState = (state) => state.currentSearchMailFolderIds;

export const getCurrentSearchKeywords: (
  state: MailSearchState,
  projectId: ProjectId
) => string = (state, projectId) => {
  const searchTerm = state.currentSearchTerm[projectId];
  if (!searchTerm) {
    return null;
  }
  return typeof searchTerm === 'string'
    ? searchTerm.toLowerCase().includes('and') ||
      searchTerm.toLowerCase().includes('or')
      ? null
      : searchTerm
    : (searchTerm as AdvancedMailSearchDto)?.keywords ?? null;
};
