import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';
import { MailFolderId } from '../../../../models/Types';
import { ActiveMailFolderAction, SetUnreadItemAction } from '../types';
import { ReduxAction } from '../../../../models/Redux';

/** Contact fetching */
export const FETCH_MAIL_FOLDERS_ME_REQUEST =
  PRIO + 'FETCH_MAIL_FOLDERS_ME_REQUEST';
export const FETCH_MAIL_FOLDERS_ME_COMMIT =
  PRIO + 'FETCH_MAIL_FOLDERS_ME_COMMIT';
export const FETCH_MAIL_FOLDERS_ME_ROLLBACK =
  PRIO + 'FETCH_MAIL_FOLDERS_ME_ROLLBACK';

export const fetchMailFoldersMe = () => ({
  type: FETCH_MAIL_FOLDERS_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/MailFolder/Me`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_MAIL_FOLDERS_ME_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MAIL_FOLDERS_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.mailFolders.fetchError',
          timeout: 6,
        },
      },
    },
  },
});

export const UPDATE_UNREAD_ITEM_COUNT_ME = PRIO + 'UPDATE_UNREAD_ITEM_COUNT_ME';

export const setUnreadItemCountMe: (
  mailFolderId: MailFolderId,
  itemCount: number,
  option: 'add' | 'remove'
) => SetUnreadItemAction = (mailFolderId, itemCount, option) => ({
  type: UPDATE_UNREAD_ITEM_COUNT_ME,
  mailFolderId,
  itemCount,
  option,
});

export const UPDATE_ACTIVE_MAILFOLDER_ME = PRIO + 'UPDATE_ACTIVE_MAILFOLDER_ME';

export const setActiveMailFolderMe: (
  mailFolderId: MailFolderId
) => ActiveMailFolderAction = (mailFolderId) => ({
  type: UPDATE_ACTIVE_MAILFOLDER_ME,
  mailFolderId,
});

export const SET_OPEN_MAILFOLDER_KEYS_ME = PRIO + 'SET_OPEN_MAILFOLDER_KEYS_ME';

export const setOpenMailFolderMe: (
  mailFolderIds: MailFolderId[]
) => ReduxAction = (mailFolderIds) => ({
  type: SET_OPEN_MAILFOLDER_KEYS_ME,
  mailFolderIds,
});

export const ADD_OPEN_MAILFOLDER_KEY_ME = PRIO + 'ADD_OPEN_MAILFOLDER_KEY_ME';

export const addOpenMailFolderMe: (
  mailFolderId: MailFolderId
) => ReduxAction = (mailFolderId) => ({
  type: ADD_OPEN_MAILFOLDER_KEY_ME,
  mailFolderId,
});
