import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  getProject,
  getProjectRedirect,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch } from 'react-redux';
import { Project } from '../../../models/Project';
import { updateProject } from '../actions';
import { Col, Form, Input, notification, Result, Row } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { colon, rowGutter } from '../../../util/forms';
import CompanyPicker from '../../companies/components/CompanyPicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import {
  apiGetAdditionalProjectInformation,
  apiUpdateAdditionalProjectInformation,
} from '../api';
import equals from 'deep-equal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px`,
    overflow: 'auto',
  },
  form: {},
  submitButtonFormItem: {
    textAlign: 'right',
  },
}));

interface FormValuesProject {
  name: string;
  shortName: string;
  subsidiaryId: string;
  companyId: string;
  masterPlanId: string;
  parentProject: string;
  projectId: string;
  rowVersion: string;
}

interface FormValuesAddedInfo {
  additionalInformation1: string;
  additionalInformation2: string;
  additionalInformation3: string;
  additionalInformation4: string;
  additionalInformation5: string;
}

interface EditProjectProps {}

export const EditProject: React.FC<EditProjectProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const redirect = useSelector<RootReducerState, string>((state) =>
    getProjectRedirect(state, projectId)
  );

  const [initialDataProjectInfo, setInitialDataProjectInfo] =
    useState<FormValuesProject>({
      name: '',
      shortName: '',
      subsidiaryId: '',
      companyId: '',
      masterPlanId: '',
      parentProject: '',
      projectId: '',
      rowVersion: '',
    });

  const [initialDataAddedInfo, setInitialDataAddedInfo] =
    useState<FormValuesAddedInfo>({
      additionalInformation1: '',
      additionalInformation2: '',
      additionalInformation3: '',
      additionalInformation4: '',
      additionalInformation5: '',
    });

  const [isSavingButtonDisabled, setIsSavingButtonDisabled] =
    useState<boolean>(true);

  const [addedInfo1, setAddedInfo1] = useState<string>('');
  const [addedInfo2, setAddedInfo2] = useState<string>('');
  const [addedInfo3, setAddedInfo3] = useState<string>('');
  const [addedInfo4, setAddedInfo4] = useState<string>('');
  const [addedInfo5, setAddedInfo5] = useState<string>('');

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const updateAdditionalProjectInformation = async () => {
    const { result } = await apiUpdateAdditionalProjectInformation(projectId, {
      newContent1: addedInfo1,
      newContent2: addedInfo2,
      newContent3: addedInfo3,
      newContent4: addedInfo4,
      newContent5: addedInfo5,
    });

    if (result.status >= 400) {
      notification.open({
        message: t('common:success'),
        description: t(
          'projects:errorMessages.updateAdditionalProjectInformationError'
        ),
      });
    }
  };

  const onFinish = (updatedProject: Project) => {
    dispatch(
      updateProject(
        { ...updatedProject, rowVersion: project.rowVersion },
        project
      )
    );
    updateAdditionalProjectInformation();
    setInitialDataAddedInfo({
      additionalInformation1: addedInfo1,
      additionalInformation2: addedInfo2,
      additionalInformation3: addedInfo3,
      additionalInformation4: addedInfo4,
      additionalInformation5: addedInfo5,
    });
    setIsSavingButtonDisabled(true);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.setFieldsValue(project);
    setInitialDataProjectInfo({
      name: project.name,
      shortName: project.shortName,
      subsidiaryId: project.subsidiaryId,
      companyId: project.companyId,
      masterPlanId: project.masterPlanId,
      parentProject: project.parentProject,
      projectId: project.projectId,
      rowVersion: project.rowVersion,
    });
  }, [project, form]);

  useEffect(() => {
    const fetchAddtionalProjectInformation = async () => {
      const { data, result } =
        await apiGetAdditionalProjectInformation(projectId);

      if (result.status >= 200 && result.status < 400) {
        setAddedInfo1(data?.content1);
        setAddedInfo2(data?.content2);
        setAddedInfo3(data?.content3);
        setAddedInfo4(data?.content4);
        setAddedInfo5(data?.content5);

        setInitialDataAddedInfo({
          additionalInformation1: data?.content1,
          additionalInformation2: data?.content2,
          additionalInformation3: data?.content3,
          additionalInformation4: data?.content4,
          additionalInformation5: data?.content5,
        });
      }

      if (result.status >= 400) {
        notification.open({
          message: t('common:error'),
          description: t(
            'projects:errorMessages.fetchAdditionalProjectInformationError'
          ),
        });
      }
    };
    fetchAddtionalProjectInformation();
  }, [projectId, t]);

  useEffect(() => {
    form.setFieldsValue({
      additionalInformation1: addedInfo1,
      additionalInformation2: addedInfo2,
      additionalInformation3: addedInfo3,
      additionalInformation4: addedInfo4,
      additionalInformation5: addedInfo5,
    });
  }, [form, addedInfo1, addedInfo2, addedInfo3, addedInfo4, addedInfo5]);
  //#endregion

  if (redirect) {
    return <Navigate to={`../../../${redirect}/settings/edit`} />;
  }

  if (!project)
    return (
      <Result
        status="404"
        title="404"
        subTitle={t('projects:errorMessages.notFound')}
      />
    );

  return (
    <Form
      form={form}
      className={classes.root}
      initialValues={project}
      onFinish={onFinish}
      layout="vertical"
      onValuesChange={(changedValues, allValues) => {
        const allInitialData = {
          ...initialDataProjectInfo,
          ...initialDataAddedInfo,
        };
        setIsSavingButtonDisabled(equals(allValues, allInitialData));
      }}
    >
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={12}>
          <h2>{t('projects:settings.headline')}</h2>
        </Col>
      </Row>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={12}>
          <Form.Item
            name="name"
            label={t('projects:form.labels.name')}
            validateTrigger="onBlur"
            colon={colon}
            /*
            rules={[
              {
                required: true,
                message: t('projects:form.validation.missingName'),
              },
              () => ({
                async validator(rule, value) {
                  if (
                    validationResult.errors &&
                    validationResult.errors.ProjectName &&
                    validationResult.errors.ProjectName.some(
                      (x) => x === 'projects:form.validation.invalidName'
                    )
                  ) {
                    return Promise.reject(
                      t('projects:form.validation.invalidName')
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            */
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="shortName"
            label={t('projects:form.labels.shortName')}
            colon={colon}
            rules={[
              {
                required: true,
                message: t('projects:form.validation.missingShortName'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            hidden
            name="rowVersion"
            //label={t('projects:form.labels.shortName')}
            colon={colon}
          >
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            hidden
            name="masterPlanId"
            //label={t('projects:form.labels.shortName')}
            colon={colon}
          >
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            hidden
            name="parentProject"
            //label={t('projects:form.labels.shortName')}
            colon={colon}
          >
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            hidden
            name="projectId"
            //label={t('projects:form.labels.shortName')}
            colon={colon}
          >
            <Input type="hidden" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={12}>
          <Form.Item
            name="subsidiaryId"
            label={t('projects:form.labels.subsidiary')}
            rules={[
              {
                required: true,
                message: t('projects:form.validation.missingSubsidiary'),
              },
            ]}
          >
            <CompanyPicker companyType="InternalCompany" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="companyId"
            label={t('projects:form.labels.companyId')}
            rules={[
              {
                required: true,
                message: t('projects:form.validation.missingCompanyId'),
              },
            ]}
          >
            <CompanyPicker />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="additionalInformation1"
            label={t('projects:form.labels.additionalProjectInformation1')}
          >
            <Input
              value={addedInfo1}
              onChange={(value) => setAddedInfo1(value.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="additionalInformation2"
            label={t('projects:form.labels.additionalProjectInformation2')}
          >
            <Input
              value={addedInfo2}
              onChange={(value) => setAddedInfo2(value.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="additionalInformation3"
            label={t('projects:form.labels.additionalProjectInformation3')}
          >
            <Input
              value={addedInfo3}
              onChange={(value) => setAddedInfo3(value.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="additionalInformation4"
            label={t('projects:form.labels.additionalProjectInformation4')}
          >
            <Input
              value={addedInfo4}
              onChange={(value) => setAddedInfo4(value.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="additionalInformation5"
            label={t('projects:form.labels.additionalProjectInformation5')}
          >
            <Input
              value={addedInfo5}
              onChange={(value) => setAddedInfo5(value.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end">
        <Col span={8}>
          <Form.Item className={classes.submitButtonFormItem}>
            <Button htmlType="submit" disabled={isSavingButtonDisabled}>
              {t('common:save')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditProject;
