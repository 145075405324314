import { User } from '../../../models/User';
import { Reducer } from 'redux';
import { ContactId, GlobalRole } from '../../../models/Types';
import { FETCH_USER_ME_COMMIT, FETCH_USER_ME_REQUEST, FETCH_USER_ME_ROLLBACK } from '../actions/me';
import { CLEAR_PRIO_CACHE } from '../../../actions';

interface UserMeAction {
  type: string;
  payload: { userData: User };
}

export interface PrioUserState {
  user: User;
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const initialState: PrioUserState = {
  user: null,
  isFetching: false,
  hasError: false
}

const me: Reducer<PrioUserState, UserMeAction> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_ME_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_USER_ME_COMMIT: {
      return {
        ...state,
        user: {
          ...action.payload.userData,
        },
        isFetching: false,
      };
    }
    case FETCH_USER_ME_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'users:errorMessages.fetchUserMeError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default me;

export const getUserMe: (state: PrioUserState) => User = (state) => state.user;
export const getUserMeContactId: (state: PrioUserState) => ContactId = (state) =>
  state?.user?.id;
export const getGlobalRoles: (state: PrioUserState) => GlobalRole[] = (state) =>
  state?.user?.prioData.globalRoles ?? [];

export const getIsFetching: (state: PrioUserState) => boolean = (state) =>
  state.isFetching;
export const getHasError: (state: PrioUserState) => boolean = (state) =>
  state.hasError;
export const getErrorMessage: (state: PrioUserState) => string = (state) =>
  state.errorMessage;
