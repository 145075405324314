import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Result } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import {
  getContact,
  getContactRedirect,
  getContactsDrawerState,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import ContactDetails from './ContactDetails';
import { makePrioStyles } from '../../../theme/utils';
import {
  setContactsDrawerView,
  setContactsDrawerState,
  closeContactsDrawer,
} from '../../contacts/actions/drawer';
import { isTemporaryId } from '../../../util';
import {
  Contact,
  InternalContact,
  ExternalContact,
} from '../../../models/Contact';
import { apiFetchContact } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { ContactId } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
  },
  contactDetails: {
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    alignSelf: 'stretch',
    overflow: 'hidden',
  },
  backButton: {
    marginBottom: theme.old.spacing.unit(2),
  },
}));

interface ContactDetailsDrawerProps {
  contactId?: ContactId;
  isSubDrawer?: boolean;
  onEdit?: (contactId: ContactId) => void;
  onClose?: VoidFunction;
  onRedirect?: (contactId: ContactId) => void;
}

export const ContactDetailsDrawer: React.FC<ContactDetailsDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    isSubDrawer,
    contactId: componentContactId,
    onEdit,
    onClose,
    onRedirect,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { selectedContact } = useSelector(getContactsDrawerState);
  const contactId = useMemo(
    () =>
      isSubDrawer && componentContactId ? componentContactId : selectedContact,
    [isSubDrawer, componentContactId, selectedContact]
  );
  const [contact, setContact] = useState<Contact>(null);

  const contactFromRedux = useSelector<RootReducerState, Contact>((state) =>
    contactId ? getContact(state, contactId) : null
  );

  const redirect = useSelector<RootReducerState, string>((state) =>
    getContactRedirect(state, contactId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const goBack = () => dispatch(setContactsDrawerView('list'));
  const closeDrawer = () => {
    if (isSubDrawer && onClose) {
      onClose();
    } else {
      dispatch(closeContactsDrawer());
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  React.useEffect(() => {
    if (contactId) {
      if (!contactFromRedux) {
        if (!isTemporaryId(contactId)) {
          const loadContact = async () => {
            const { result, data } = await apiFetchContact(contactId);
            if (result.status >= 200 && result.status < 300) {
              setContact(data);
            }
          };
          loadContact();
        }
      } else {
        setContact(contactFromRedux);
      }
    }
  }, [contactId, contactFromRedux]);

  useEffect(() => {
    if (redirect) {
      if (isSubDrawer && onRedirect) {
        onRedirect(redirect);
      } else {
        dispatch(setContactsDrawerState({ selectedContact: redirect }));
      }
    }
  }, [redirect, isSubDrawer, onRedirect, dispatch]);
  //#endregion

  if (contact) {
    return (
      <>
        {!isSubDrawer && (
          <Button
            onClick={goBack}
            type="link"
            iconProp={['fal', 'chevron-left']}
            className={classes.backButton}
          >
            {t('common:back')}
          </Button>
        )}
        <ContactDetails
          contact={contact as InternalContact & ExternalContact}
          className={classNames(classes.contactDetails, classes.noPadding)}
          contentClassName={classes.noPadding}
          onEdit={onEdit}
          isSubDrawer={isSubDrawer}
          noAvatar
        />
      </>
    );
  }

  if (isTemporaryId(contactId)) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <Result
          status="500"
          title={t('common:weAreSorry')}
          subTitle={t('contacts:errorMessages.createError')}
          extra={
            <Button type="link" onClick={closeDrawer}>
              {t('common:actions.close')}
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
      <PrioSpinner size="large" />
    </div>
  );
};

export default ContactDetailsDrawer;
