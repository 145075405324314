import { Moment } from 'moment-timezone';
import { TimeRecord, TimeRecordEntry } from '../../../models/TimeRecord';

export const sumUpDurationInMinutes = (timeRecordEntries: TimeRecordEntry[]) =>
  timeRecordEntries.reduce(
    (sum, entry) =>
      sum +
      Math.round(
        (Date.parse(entry.endTime) - Date.parse(entry.startTime)) / 60000
      ),
    0
  );

export const sumUpDurationInMinutesOfDay = (timeRecords: TimeRecord[]) =>
  timeRecords.reduce((sum, entry) => sum + entry.durationInMinutes, 0);

export const calculateMinutes = (time: Moment) => {
  return time.hours() * 60 + time.minutes();
};

export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const calculateRemainingQuarterSteps = (
  fromTimeInHours: number,
  pickedQuarterMinute: number
) => {
  const remainingMinutes =
    dayInMinutes - fromTimeInHours * 60 - pickedQuarterMinute - 15;

  const quarters: any[][] = [];
  var hourCount = 0;
  var quarterStep = 15;

  range(15, remainingMinutes, 15).forEach((minutes) => {
    if (minutes % 60 === 0) {
      hourCount++;
      quarterStep = 15;
      quarters.push([`${hourCount}:00`, minutes]);
    } else {
      quarters.push([`${hourCount}:${quarterStep}`, minutes]);
      quarterStep = quarterStep + 15;
    }
  });
  return quarters;
};

export const dayInMinutes = 1440;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const PRETTY_DATE_FORMAT = 'D MMM YYYY';
