import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';
import { MailFolderId, ProjectId } from '../../../../models/Types';
import { ActiveMailFolderAction, SetUnreadItemAction } from '../types';
import { ReduxAction } from '../../../../models/Redux';

/** Contact fetching */
export const FETCH_PROJECT_MAIL_FOLDERS_REQUEST =
  PRIO + 'FETCH_PROJECT_MAIL_FOLDERS_REQUEST';
export const FETCH_PROJECT_MAIL_FOLDERS_COMMIT =
  PRIO + 'FETCH_PROJECT_MAIL_FOLDERS_COMMIT';
export const FETCH_PROJECT_MAIL_FOLDERS_ROLLBACK =
  PRIO + 'FETCH_PROJECT_MAIL_FOLDERS_ROLLBACK';

export const fetchMailFoldersProject = (projectId: string) => ({
  type: FETCH_PROJECT_MAIL_FOLDERS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/MailFolder/${projectId}`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_PROJECT_MAIL_FOLDERS_COMMIT, meta: { projectId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_MAIL_FOLDERS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.mailFolders.fetchError',
          timeout: 6,
        },
        meta: { projectId },
      },
    },
    projectId,
  },
});

export const UPDATE_UNREAD_ITEM_COUNT_PROJECT =
  PRIO + 'UPDATE_UNREAD_ITEM_COUNT_PROJECT';

export const setUnreadItemCountProject: (
  mailFolderId: MailFolderId,
  itemCount: number,
  option: 'add' | 'remove'
) => SetUnreadItemAction = (mailFolderId, itemCount, option) => ({
  type: UPDATE_UNREAD_ITEM_COUNT_PROJECT,
  mailFolderId,
  itemCount,
  option,
});

export const UPDATE_ACTIVE_MAILFOLDER_PROJECT =
  PRIO + 'UPDATE_ACTIVE_MAILFOLDER_PROJECT';

export const setActiveMailFolderInProjects: (
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => ActiveMailFolderAction = (projectId, mailFolderId) => ({
  type: UPDATE_ACTIVE_MAILFOLDER_PROJECT,
  projectId,
  mailFolderId,
});

export const SET_OPEN_MAILFOLDER_KEYS_PROJECT =
  PRIO + 'SET_OPEN_MAILFOLDER_KEYS_PROJECT';

export const setOpenMailFolderProjects: (
  projectId: ProjectId,
  mailFolderIds: MailFolderId[]
) => ReduxAction = (projectId, mailFolderIds) => ({
  type: SET_OPEN_MAILFOLDER_KEYS_PROJECT,
  projectId,
  mailFolderIds,
});

export const ADD_OPEN_MAILFOLDER_KEY_PROJECT =
  PRIO + 'ADD_OPEN_MAILFOLDER_KEY_PROJECT';

export const addOpenMailFolderProjects: (
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => ReduxAction = (projectId, mailFolderId) => ({
  type: ADD_OPEN_MAILFOLDER_KEY_PROJECT,
  projectId,
  mailFolderId,
});
