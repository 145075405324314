import { combineReducers } from 'redux';
import mailFolders, * as fromMailFolders from './mailFolders';
import messages, * as fromMessages from './messages';
import drafts, * as fromDrafts from './drafts';
import mailNavigation, * as fromMailNavigation from './mailNavigation';
import categories, * as fromCategories from './categories';
import search, * as fromSearch from './search';
import specialMailFolder, * as fromSpecialMailFolder from './specialMailFolder';
import { MailFolder } from '../../../../models/MailFolder';
import { Message } from '../../../../models/Message';
import {
  MailCategoryColorName,
  MailFolderId,
  MessageId,
  ProjectId,
} from '../../../../models/Types';
import {
  MessageIdsByMailFolderId,
  ByMessageId,
  SpecialMailFolders,
  MailListNavigationState,
  MailFolderByMailFolderId,
} from '../../actions/types';
import { AdvancedMailSearchDto } from '../../../../models/MailSearch';

export interface ProjectMailState {
  mailFolders: fromMailFolders.MailFoldersState;
  messages: fromMessages.MessagesState;
  drafts: fromDrafts.DraftsMessagesStateByProjectId;
  mailNavigation: fromMailNavigation.MailNavigationState;
  categories: fromCategories.CategoryState;
  search: fromSearch.MailSearchState;
  specialMailFolder: fromSpecialMailFolder.SpecialMailFolderByProjectId;
}

export default combineReducers<ProjectMailState>({
  mailFolders,
  messages,
  drafts,
  mailNavigation,
  categories,
  search,
  specialMailFolder,
});

//#region ------------------------------ mailFolders
export const getAllProjectMailFolders: (
  state: ProjectMailState,
  projectId: string
) => Array<MailFolder> = (state, projectId) =>
  fromMailFolders.getAllMailFolders(state.mailFolders, projectId);
export const getProjectMailFoldersById: (
  state: ProjectMailState
) => MailFolderByMailFolderId = (state) =>
  fromMailFolders.getMailFoldersById(state.mailFolders);

export const getProjectMailFolder: (
  state: ProjectMailState,
  id: string
) => MailFolder = (state, id) =>
  fromMailFolders.getMailFolder(state.mailFolders, id);
export const getProjectMailFoldersIsFetching: (
  state: ProjectMailState,
  projectId: ProjectId
) => boolean = (state, projectId) =>
  fromMailFolders.getIsFetching(state.mailFolders, projectId);
export const getProjectMailFoldersHasError: (
  state: ProjectMailState
) => boolean = (state) => fromMailFolders.getHasError(state.mailFolders);
export const getProjectMailFoldersErrorMessage: (
  state: ProjectMailState
) => string = (state) => fromMailFolders.getErrorMessage(state.mailFolders);

export const getActiveProjectMailFolderId: (
  state: ProjectMailState,
  projectId: ProjectId
) => string = (state, projectId) =>
  fromMailFolders.getActiveMailFolderId(state.mailFolders, projectId);

export const getOpenMailFolderKeysProjects: (
  state: ProjectMailState,
  projectId: ProjectId
) => MailFolderId[] = (state, projectId) =>
  fromMailFolders.getOpenMailFolderKeys(state.mailFolders, projectId);
//#endregion

//#region ------------------------------ messages
export const getAllProjectMessages: (
  state: ProjectMailState,
  projectId: string,
  mailFolderId: string
) => Array<Message> = (state, projectId, mailFolderId) =>
  fromMessages.getAllMessages(state.messages, projectId, mailFolderId);

export const getMessageIdsByProjecId: (
  state: ProjectMailState
) => fromMessages.ByProjectId = (state) =>
  fromMessages.getMessageIdsByProjecId(state.messages);

export const getProjectMessage: (
  state: ProjectMailState,
  id: string
) => Message = (state, id) => fromMessages.getMessage(state.messages, id);
export const getProjectMessagesById: (state: ProjectMailState) => {
  [messageId: string]: Message;
} = (state) => fromMessages.getMessagesById(state.messages);
export const getProjectMessagesIsFetching: (
  state: ProjectMailState,
  projectId: ProjectId,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromMessages.getIsFetching(state.messages, projectId, mailFolderId);
export const getProjectMessagesHasError: (
  state: ProjectMailState
) => boolean = (state) => fromMessages.getHasError(state.messages);

export const getIsSingleProjectMessageFetching: (
  state: ProjectMailState
) => boolean = (state) =>
  fromMessages.getIsSingleMessageFetching(state.messages);

export const getProjectMessagesNextLink: (
  state: ProjectMailState,
  projectId: string,
  mailFolderId: string
) => string = (state, projectId, mailFolderId) =>
  fromMessages.getNextLink(state.messages, projectId, mailFolderId);

export const getProjectsNextLinkState: (
  state: ProjectMailState
) => fromMessages.NextLinkState = (state) =>
  fromMessages.getNextLinkState(state.messages);

export const getProjectMessagesErrorMessage: (
  state: ProjectMailState
) => string = (state) => fromMessages.getErrorMessage(state.messages);
export const getProjectMessagesByIdState: (
  state: ProjectMailState
) => ByMessageId = (state) => state.messages.byId;
export const getProjectMessagesIdsState: (
  state: ProjectMailState,
  projectId: string
) => MessageIdsByMailFolderId = (state, projectId) =>
  state.messages.ids[projectId] ?? {};
//#endregion

//#region ------------------------------ drafts
export const getDraftMessagesProject: (
  state: ProjectMailState,
  projectId: ProjectId
) => Message[] = (state, projectId) =>
  fromDrafts.getDraftMessages(state.drafts, projectId);

export const getDraftMessageProject: (
  state: ProjectMailState,
  projectId: ProjectId,
  messageId: MessageId
) => Message = (state, projectId, messageId) =>
  fromDrafts.getDraftMessage(state.drafts, projectId, messageId);

export const getDraftMessageIsInitialProject: (
  state: ProjectMailState,
  projectId: ProjectId,
  messageId: MessageId
) => boolean = (state, projectId, messageId) =>
  fromDrafts.getDraftMessageIsInitial(state.drafts, projectId, messageId);

export const getActiveDraftMessageIdProject: (
  state: ProjectMailState,
  projectId: ProjectId
) => MessageId = (state, projectId) =>
  fromDrafts.getActiveDraftMessageId(state.drafts, projectId);

export const getSendingMessageIds: (state: ProjectMailState) => MessageId[] = (
  state
) => fromDrafts.getSendingMessageIds(state.drafts);
//#endregion

//#region ------------------------------ categories
export const getColorForProjectMessageCategory: (
  state: ProjectMailState,
  projectId: ProjectId,
  displayName: string
) => string = (state, projectId, displayName) =>
  fromCategories.getColorForMessageCategory(
    state.categories,
    projectId,
    displayName
  );

export const getMessageCategoryColorMapForProject: (
  state: ProjectMailState,
  projectId: ProjectId
) => { [displayName: string]: MailCategoryColorName } = (state, projectId) =>
  fromCategories.getMessageCategoryColorMapForProject(
    state.categories,
    projectId
  );
//#endregion

//#region ------------------------------ search
export const getSearchProjectIsFetching: (
  state: ProjectMailState,
  projectId: ProjectId,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromSearch.getIsFetching(state.search, projectId, mailFolderId);
export const getSearchProjectHasError: (state: ProjectMailState) => boolean = (
  state
) => fromSearch.getHasError(state.search);
export const getSearchProjectErrorMessage: (
  state: ProjectMailState
) => string = (state) => fromSearch.getErrorMessage(state.search);

export const getSearchProjectByIdState: (
  state: ProjectMailState
) => ByMessageId = (state) => fromSearch.getMessagesById(state.search);
export const getSearchProjectIds: (
  state: ProjectMailState,
  projectId: ProjectId
) => string[] = (state, projectId) =>
  fromSearch.getMessageIds(state.search, projectId);

export const getSearchProjectIdsState: (state: ProjectMailState) => {
  [projectId: ProjectId]: string[];
} = (state) => fromSearch.getMessageIdsState(state.search);

export const getSearchProjectMessage: (
  state: ProjectMailState,
  id: MessageId
) => Message = (state, id) => fromSearch.getMessage(state.search, id);

export const getSearchProjectMessages: (
  state: ProjectMailState,
  projectId: ProjectId
) => Message[] = (state, projectId) =>
  fromSearch.getMessages(state.search, projectId);

export const getSearchProjectNextLink: (
  state: ProjectMailState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => string = (state, projectId, mailFolderId) =>
  fromSearch.getNextLink(state.search, projectId, mailFolderId);

export const getlastSearchTermMailProject: (
  state: ProjectMailState,
  projectId: ProjectId
) => string | AdvancedMailSearchDto = (state, projectId) =>
  fromSearch.getlastSearchTermMail(state.search, projectId);

export const getCurrentSearchTermProject: (
  state: ProjectMailState,
  projectId: ProjectId
) => string | AdvancedMailSearchDto = (state, projectId) =>
  fromSearch.getCurrentSearchTerm(state.search, projectId);

export const getCurrentSearchTermState = (state: ProjectMailState) =>
  fromSearch.getCurrentSearchTermState(state.search);

export const getCurrentSearchMailFolderIdProject: (
  state: ProjectMailState,
  projectId: ProjectId
) => MailFolderId = (state, projectId) =>
  fromSearch.getCurrentSearchMailFolderId(state.search, projectId);

export const getCurrentSearchMailFolderIdState = (state: ProjectMailState) =>
  fromSearch.getCurrentSearchMailFolderIdState(state.search);

export const getCurrentSearchKeywordsProject: (
  state: ProjectMailState,
  projectId: ProjectId
) => string = (state, projectId) =>
  fromSearch.getCurrentSearchKeywords(state.search, projectId);
//#endregion

//#region ------------------------------ specialMailFolders
export const getSpecialMailFoldersState: (
  state: ProjectMailState
) => fromSpecialMailFolder.SpecialMailFolderByProjectId = (state) =>
  fromSpecialMailFolder.getSpecialMailFoldersState(state.specialMailFolder);

export const getSpecialProjectMailFolders: (
  state: ProjectMailState,
  projectId: ProjectId
) => SpecialMailFolders = (state, projectId) =>
  fromSpecialMailFolder.getSpecialMailFolders(
    state.specialMailFolder,
    projectId
  );

export const isDeletedProjectFolder: (
  state: ProjectMailState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromSpecialMailFolder.isDeletedFolder(
    state.specialMailFolder,
    projectId,
    mailFolderId
  );

export const isSendProjectFolder: (
  state: ProjectMailState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromSpecialMailFolder.isSendFolder(
    state.specialMailFolder,
    projectId,
    mailFolderId
  );

export const isDraftProjectFolder: (
  state: ProjectMailState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromSpecialMailFolder.isDraftFolder(
    state.specialMailFolder,
    projectId,
    mailFolderId
  );
//#endregion

//#region ------------------------------ mailNavigation
export const getProjectMailListNavigationState: (
  state: ProjectMailState
) => MailListNavigationState = (state) =>
  fromMailNavigation.getListNavigationStateProjects(state.mailNavigation);

export const isActiveProjectMailEntry: (
  state: ProjectMailState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMailNavigation.isActiveProjects(state.mailNavigation, messageId);

export const isActiveScrollableProjectMailEntry: (
  state: ProjectMailState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMailNavigation.isActiveAndScrollProjects(state.mailNavigation, messageId);

export const getActiveProjectMessageId: (
  state: ProjectMailState
) => MessageId = (state) =>
  fromMailNavigation.getActiveMessageIdProjects(state.mailNavigation);

export const getProjectMailMenuOpen: (state: ProjectMailState) => boolean = (
  state
) => fromMailNavigation.getMailMenuOpenProjects(state.mailNavigation);
//#endregion
