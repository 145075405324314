import React, { useEffect, useMemo, useState } from 'react';

import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';

import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  CustomMarker,
  TimelineItemBase,
  TimelineGroup,
} from 'react-calendar-timeline';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';

import moment, { Moment } from 'moment';
import { List, Checkbox, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { TimelineItem } from './TimelineItem';
import { TimelineItemHoliday } from './TimelineItemHoliday';

import 'react-calendar-timeline/lib/Timeline.css';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import ContactPicker from '../../../contacts/components/ContactPicker';
import { ContactId, OfficeId } from '../../../../models/Types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { Contact } from '../../../../models/Contact';
import { debounceFunction } from '../../../../util';

const lineHeightHeader = 62;
const lineHeight = 75;
const itemHeightRatio = 0.4;

const useStyles = makePrioStyles((theme) => ({
  root: {},
  timeline: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 24px',
    '& .rct-header-root': {
      borderTop: theme.old.borders.content,
      borderBottom: theme.old.borders.content,
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
    '& .rct-calendar-header': {
      border: 'none',
    },
    '& .rct-outer': {
      flex: 1,
      overflowY: 'scroll',
      height: 'unset!important',
    },
    '& .rct-sidebar': {
      border: 'none',
      '& .rct-sidebar-row': {
        padding: 0,
        borderBottom: theme.old.borders.content,
        borderRight: theme.old.borders.content,
        '&.rct-sidebar-row-odd': {
          backgroundColor: theme.old.palette.backgroundPalette.content,
        },
      },
    },
    '& .rct-vertical-lines .rct-vl': {
      borderLeft: theme.old.borders.content,
      '&.rct-vl-first': {
        borderLeftWidth: 1,
      },
      '&.rct-day-0': {
        backgroundColor: 'rgba(140, 125, 115, 0.1)',
      },
      '&.rct-day-6': {
        backgroundColor: 'rgba(140, 125, 115, 0.1)',
      },
    },
    '& .rct-horizontal-lines .row': {
      backgroundColor: theme.old.palette.backgroundPalette.content,
      borderBottom: theme.old.borders.content,
    },
    '& .rct-scroll': {
      overflowX: 'hidden!important',
    },
  },
  dateEntry: {
    justifyContent: 'center',
    height: lineHeightHeader,
  },
  date: {
    fontSize: 16,
  },
  day: {
    fontSize: 12,
    fontWeight: 'lighter',
  },
  contactPickerContainer: {
    borderRight: theme.old.borders.content,
  },
  contactPicker: {
    width: '100%',
    height: '100%',
    '& > .ant-select-selector': {
      border: 'none!important',
      height: '100%!important',
    },
    '& > .ant-select-selector > span > input': {
      height: '100%!important',
    },
    '& .ant-select-selection-placeholder': {
      lineHeight: '62px!important',
      marginLeft: 45,
    },
  },
  row: {
    backgroundColor: theme.old.palette.chromaticPalette.red,
    color: theme.old.palette.chromaticPalette.red,
  },
  rowMarker: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'transparent',
    borderBottom: theme.old.borders.content,
  },
  listItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingTop: theme.old.spacing.unit(2),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
    height: lineHeight,
  },
  listItemMeta: {
    alignItems: 'center',
    '& .ant-list-item-meta-avatar': {
      marginRight: theme.old.spacing.unit(2),
    },
    '& .ant-list-item-meta-description': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& .ant-list-item-meta-title': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  header: {
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(3)}px`,
  },
  title: {
    fontSize: 18,
  },
  month: {
    fontSize: 18,
    minWidth: 150,
    textAlign: 'center',
  },
}));

export type ContactCallback = (contactId: ContactId) => void;

interface TimelineComponentProps {
  className?: string;
  monthStart?: Moment;
  monthEnd?: Moment;
  canResize?: boolean;
  canMove?: boolean;
  timelineEvents: TimelineEvent[];
  title?: string;
  sidebarWidth?: number;
  groups: AbsenceTimelineGroup[];
  onAddContact?: ContactCallback;
  onRemoveContact?: ContactCallback;
  customContacts: boolean;
  onReload: (period: { from: Moment; to: Moment }) => void;
  officeId: OfficeId | null;
  context: 'me' | 'office' | 'global';
}

export const TimelineComponent: React.FC<TimelineComponentProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    canResize,
    canMove,
    timelineEvents,
    title,
    sidebarWidth,
    groups,
    customContacts,
    onAddContact,
    onRemoveContact,
    onReload,
    officeId,
    context,
  } = props;

  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [currentDay, setCurrentDay] = useState<Moment>(moment());

  const [monthStart, setMonthStart] = useState<Moment>(
    currentDay.clone().startOf('month')
  );
  const [monthEnd, setMonthEnd] = useState<Moment>(
    currentDay.clone().endOf('month')
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void
  ) => {
    updateScrollCanvas(monthStart.valueOf(), monthEnd.valueOf());
  };

  const debouncedOnRollback = useMemo(
    () =>
      debounceFunction((from: Moment, to: Moment) => {
        onReload({
          from,
          to,
        });
      }, 500),
    [onReload]
  );
  //#endregion

  //#region ------------------------------ Components
  const customMarkerMap = (_, index: number) => {
    return (
      <CustomMarker date={monthStart.valueOf()} key={index}>
        {({ styles, date }) => {
          return (
            <div
              className={classes.rowMarker}
              style={{
                height: lineHeight,
                top: index * 75,
                left: styles.left,
              }}
            />
          );
        }}
      </CustomMarker>
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  });
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row className={classes.header} alignItems="baseline">
        <Typography.Text
          className={classes.title}
          style={{ width: sidebarWidth - theme.old.spacing.unit(3) }}
        >
          {title}
        </Typography.Text>
        <Button
          type="link"
          shape="circle"
          onClick={() => {
            const today = moment();
            setCurrentDay(today);
            setMonthStart(today.clone().startOf('month'));
            setMonthEnd(today.clone().endOf('month'));
            debouncedOnRollback(
              today.clone().subtract(1, 'month').startOf('month'),
              today.clone().add(1, 'month').endOf('month')
            );
          }}
          iconProp={['fal', 'calendar-alt']}
        />
        <Button
          type="link"
          shape="circle"
          onClick={() => {
            const newCurrentDay = currentDay.clone().subtract(1, 'month');
            setCurrentDay(newCurrentDay);
            setMonthStart(newCurrentDay.clone().startOf('month'));
            setMonthEnd(newCurrentDay.clone().endOf('month'));
            debouncedOnRollback(
              newCurrentDay.clone().subtract(1, 'month').startOf('month'),
              newCurrentDay.clone().add(1, 'month').endOf('month')
            );
          }}
          iconProp={['fal', 'chevron-left']}
        />
        <Typography.Text className={classes.month}>
          {currentDay.format('MMMM YYYY')}
        </Typography.Text>
        <Button
          type="link"
          shape="circle"
          onClick={() => {
            const newCurrentDay = currentDay.clone().add(1, 'month');
            setCurrentDay(newCurrentDay);
            setMonthStart(newCurrentDay.clone().startOf('month'));
            setMonthEnd(newCurrentDay.clone().endOf('month'));
            debouncedOnRollback(
              newCurrentDay.clone().subtract(1, 'month').startOf('month'),
              newCurrentDay.clone().add(1, 'month').endOf('month')
            );
          }}
          iconProp={['fal', 'chevron-right']}
        />
      </Flex.Row>
      <Timeline<TimelineEvent, AbsenceTimelineGroup>
        //@ts-ignore
        className={classes.timeline}
        resizeDetector={containerResizeDetector}
        groups={groups}
        items={timelineEvents}
        visibleTimeStart={monthStart.valueOf()}
        visibleTimeEnd={monthEnd.valueOf()}
        onTimeChange={handleOnTimeChange}
        canChangeGroup={false}
        canResize={canResize}
        canMove={canMove}
        sidebarWidth={sidebarWidth}
        lineHeight={lineHeight}
        itemHeightRatio={itemHeightRatio}
        traditionalZoom={true}
        groupRenderer={({ group }) => {
          return (
            <List.Item
              className={classes.listItem}
              actions={
                customContacts
                  ? [
                      <Button
                        type="link"
                        onClick={() => onRemoveContact(group.contactId)}
                        iconProp={['fal', 'times']}
                        shape="circle"
                      ></Button>,
                    ]
                  : null
              }
            >
              <List.Item.Meta
                className={classes.listItemMeta}
                title={group.title}
                description={group.description}
                avatar={<Checkbox />}
              />
            </List.Item>
          );
        }}
        horizontalLineClassNamesForGroup={(_) => {
          return ['row'];
        }}
        itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
          return item.isHoliday ? (
            <TimelineItemHoliday
              item={item}
              itemContext={itemContext}
              getItemProps={getItemProps}
              getResizeProps={getResizeProps}
              itemHeight={lineHeight}
              itemHeightRatio={itemHeightRatio}
            />
          ) : (
            <TimelineItem
              item={item}
              itemContext={itemContext}
              getItemProps={getItemProps}
              getResizeProps={getResizeProps}
              onReload={() =>
                onReload({
                  from: monthStart
                    .clone()
                    .subtract(1, 'month')
                    .startOf('month'),
                  to: monthEnd.clone().add(1, 'month').endOf('month'),
                })
              }
              officeId={officeId}
              context={context}
            />
          );
        }}
      >
        <TimelineHeaders>
          <SidebarHeader>
            {({ getRootProps }) => {
              return (
                <div
                  className={classes.contactPickerContainer}
                  {...getRootProps()}
                >
                  <ContactPicker
                    className={classes.contactPicker}
                    contactType="InternalContact"
                    excludedContactIds={groups.map((g) => g.contactId)}
                    onChange={onAddContact}
                    disabled={!customContacts}
                    label={t('absences:absenceTimeline.contactPicker.label')}
                    filter={officeId ? (c) => c.officeId === officeId : null}
                  />
                </div>
              );
            }}
          </SidebarHeader>
          <DateHeader
            unit="day"
            labelFormat={(times: Moment[], _, labelWidth: number) => {
              return times[0].format(labelWidth > 99 ? 'D dddd' : 'D dd.');
            }}
            height={lineHeightHeader}
            intervalRenderer={({ getIntervalProps, intervalContext }) => {
              return (
                <div {...getIntervalProps()}>
                  {intervalContext.interval.labelWidth > 99 ? (
                    intervalContext.intervalText
                  ) : (
                    <Flex.Column
                      className={classes.dateEntry}
                      alignItems="center"
                    >
                      <div className={classes.date}>
                        {intervalContext.intervalText.split(' ')[0]}
                      </div>
                      <div className={classes.day}>
                        {intervalContext.intervalText.split(' ')[1]}
                      </div>
                    </Flex.Column>
                  )}
                </div>
              );
            }}
          />
        </TimelineHeaders>

        <TimelineMarkers>{groups.map(customMarkerMap)}</TimelineMarkers>
      </Timeline>
    </Flex.Column>
  );
};

export default TimelineComponent;

export type AbsenceTimelineGroup = TimelineGroup<{
  description: string;
  name: string;
  contactId: ContactId;
}>;

export interface TimelineEvent extends TimelineItemBase<number> {
  id: string;
  group: number;
  title: string;
  start_time: number;
  end_time: number;
  isHoliday: boolean;
  absenceProposal: AbsenceProposal;
  additionalInfo?: AdditionalInfo;
}

export interface AdditionalInfo {
  officeName?: string;
  managerName?: string;
  contact?: Contact;
}
