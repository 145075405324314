import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { apiMessageAddCategories, apiMessageRemoveCategories } from '../../api';
import {
  getMessageCategoryColorMap,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import { Message } from '../../../../models/Message';
import { MailCategoryColorName, ProjectId } from '../../../../models/Types';
import { categorizedMessage } from '../../actions/actionControllers/messageActionController';
import { colorFromPreset } from '../../util';
import PrioSpinner from '../../../../components/PrioSpinner';
import { fetchMailCategories } from '../../actions/actionControllers/categoriesActionController';
import { abortableDispatch } from '../../../../util/abortableDispatch';
import { makePrioStyles } from '../../../../theme/utils';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  menuItem: {
    minHeight: 48,
    lineHeight: '48px',
  },
  tagIcon: {
    marginRight: theme.old.spacing.unit(2),
  },
}));

interface MailCategoryButtonProps {
  className?: string;
  projectId: ProjectId;
  selectedMessages: Message[];
  clearSelection: VoidFunction;
}

export const MailCategoryButton: React.FC<MailCategoryButtonProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, selectedMessages, projectId, clearSelection } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const colorMap = useSelector<
    RootReducerState,
    { [displayName: string]: MailCategoryColorName }
  >((state) => getMessageCategoryColorMap(state, projectId));

  const [submitting, setSubmitting] = useState<string>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [isCategoriesFetching, setIsCategoriesFetching] =
    useState<boolean>(false);

  const selectedCategories: { [displayName: string]: string[] } = useMemo(
    () =>
      Object.keys(colorMap ?? {}).reduce(
        (map, color) => ({
          ...map,
          [color]: selectedMessages
            .filter((message) =>
              (message?.categories ?? []).find((category) => category === color)
            )
            .map(({ id }) => id),
        }),
        {}
      ),
    [selectedMessages, colorMap]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleMenuClick = async ({ key }) => {
    if (key === 'sync') {
      abortableDispatch(
        fetchMailCategories(projectId, true),
        dispatch,
        undefined,
        false,
        undefined,
        () => setIsCategoriesFetching(true),
        () => setIsCategoriesFetching(false)
      );
    } else {
      const mailIdsOfColor = selectedCategories[key];
      const shouldAdd = mailIdsOfColor.length !== selectedMessages.length;
      setSubmitting(key);
      const distinctMessageIds = selectedMessages
        .map((message) => message.id)
        .filter((id) => !mailIdsOfColor.includes(id));
      const { result } = !shouldAdd
        ? await apiMessageRemoveCategories(projectId, mailIdsOfColor, [key])
        : await apiMessageAddCategories(projectId, distinctMessageIds, [key]);

      setSubmitting(null);
      if (result.status >= 200 && result.status < 300) {
        dispatch(
          categorizedMessage(
            projectId,
            (shouldAdd ? distinctMessageIds : mailIdsOfColor).map((id) => ({
              messageId: id,
              categories: [key],
            })),
            !shouldAdd
          )
        );
        clearSelection();
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:errorMessages.messages.updateMailCategoriesError'
          ),
        });
      }
      setVisible(false);
    }
  };
  //#endregion

  //#region ------------------------------ Components
  const menu = (
    <Menu onClick={handleMenuClick}>
      {!colorMap
        ? null
        : Object.entries(colorMap).map(([displayName, color]) => (
            <Menu.Item
              key={displayName}
              className={classes.menuItem}
              disabled={!!submitting}
            >
              <Flex.Row alignItems="baseline">
                <FontAwesomeIcon
                  icon={['fas', 'tag']}
                  color={colorFromPreset(color, theme)}
                  className={classes.tagIcon}
                />
                <Flex.Item flex={1}>{displayName}</Flex.Item>
                {submitting === color ? (
                  <PrioSpinner size="small" />
                ) : (
                  <FontAwesomeIcon
                    icon={['fal', 'check']}
                    style={{
                      visibility:
                        selectedCategories[displayName].length ===
                        selectedMessages.length
                          ? 'visible'
                          : 'hidden',
                    }}
                  />
                )}
              </Flex.Row>
            </Menu.Item>
          ))}
      <Menu.Divider />
      <Menu.Item key={'sync'} disabled={isCategoriesFetching}>
        <Flex.Row alignItems="center" justifyContent="center">
          <FontAwesomeIcon
            icon={['fal', 'sync']}
            className={classes.tagIcon}
            color={theme.old.typography.colors.muted}
          />
          <Flex.Item>{t('mail:loadCategories')}</Flex.Item>
        </Flex.Row>
      </Menu.Item>
    </Menu>
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Dropdown
      visible={visible}
      onVisibleChange={setVisible}
      overlay={menu}
      className={classNames(classes.root, className)}
      trigger={['click']}
    >
      <Button suffixIconProp={['fal', 'chevron-down']} type="default">
        {t('mail:navigationBar.categorize')}
      </Button>
    </Dropdown>
  );
};

export default MailCategoryButton;
