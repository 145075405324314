import React from 'react';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundImage:
      'repeating-linear-gradient(45deg, transparent, transparent 2px, rgba(140, 125, 115, 0.1) 2px, rgba(140, 125, 115, 0.1) 4px)',
    border: 'none',
    borderRadius: 2,
    boxSizing: 'border-box',
    fontSize: 12,
    position: 'absolute',
    color: theme.old.typography.colors.inverted,
  },
}));

interface TimelineItemHolidayProps {
  item?: any;
  timelineContext?: any;
  itemContext?: any;
  getItemProps?: any;
  getResizeProps?: any;

  itemHeight?: number;
  itemHeightRatio?: number;
}

export const TimelineItemHoliday: React.FC<TimelineItemHolidayProps> = (
  props
) => {
  const { item, getItemProps, itemContext, itemHeight, itemHeightRatio } =
    props;
  const classes = useStyles();

  const style = {
    height: itemHeight,
    left: itemContext.dimensions.left,
    lineHeight: getItemProps(item.itemProps).style.lineHeight,
    top:
      itemContext.dimensions.top -
      (itemHeight - itemHeight * itemHeightRatio) / 2,
    width: itemContext.dimensions.width,
    zIndex: getItemProps(item.itemProps).style.zIndex,
    borderRadius: 0,
  };

  return (
    <div
      {...getItemProps(item.itemProps)}
      style={style}
      className={classes.root}
      title=""
    />
  );
};

export default TimelineItemHoliday;
