import { ApiResult, apiUrl } from '../../../api';
import { User } from '../../../models/User';
import { EMailValidationResult } from '../../../models/ValidationResults';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiValidateEmail: (
  email: string
) => Promise<ApiResult<EMailValidationResult>> = async (email) => {
  const result = await fetchWithRetry(
    `${apiUrl}/user/User/validateNewUserEmail?email=${encodeURI(email)}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiImportAadUsers: (
  azureADIds: string[]
) => Promise<ApiResult<void>> = async (azureADIds) => {
  const result = await fetchWithRetry(`${apiUrl}/user/User/importAADUser`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(
      azureADIds.map((azureADId) => ({
        azureADId,
        globalRoles: ['globalEmployee'],
      }))
    ),
  });
  return {
    result,
  };
};

export const apiFetchImportableUsers: (
  searchInput?: string
) => Promise<ApiResult<User[]>> = async (searchInput) => {
  const result = await fetchWithRetry(
    `${apiUrl}/user/User/getAADUser?excludeInternalContacts=true${
      searchInput ? `&searchString=${searchInput}` : ''
    }`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
