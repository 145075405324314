import React, { ReactNode, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Skeleton, Tabs, Typography } from 'antd';
import MessageDetailsPage from './MessageDetailsPage';
import { MailFolderId, MessageId, ProjectId } from '../../../models/Types';
import {
  getActiveDraftMessageId,
  getActiveMailFolderId,
  getActiveMessageId,
  getActiveProjectInMessageCenter,
  getDraftMessage,
  getDraftMessageIsInitial,
  getDraftMessages,
  getMailFolder,
  getMessage,
  getSearchMessage,
  getSpecialMailFolders,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { makePrioStyles } from '../../../theme/utils';
import { SpecialMailFolders } from '../actions/types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DraftMessagePage from './DraftMessagePage';
import { Message } from '../../../models/Message';
import {
  removeDraftMessage,
  setActiveDraftMessageId,
} from '../actions/actionControllers/draftsActionController';
import { deleteMessageSagaAction } from '../actions/sagas';
import { useLocation, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import { MailFolder } from '../../../models/MailFolder';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '&.ant-tabs': {
      width: '100%',
      overflow: 'hidden',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-tabs-nav::before': {
      border: 'none',
    },
    '& .ant-tabs-tab-btn': {
      overflow: 'hidden',
      flex: 1,
    },
  },
  skeleton: {
    '& .ant-skeleton-title': {
      margin: 0,
    },
    '& .ant-skeleton-paragraph': {
      display: 'none',
    },
  },
}));

const draftMessageIdsSelector = (projectId: ProjectId) =>
  createSelector<[(state: RootReducerState) => Message[]], MessageId[]>(
    (state) => getDraftMessages(state, projectId),
    (draftMessages) => draftMessages.map((draftMessage) => draftMessage.id)
  );

const useDraftMessageIds = (projectId: ProjectId) => {
  const draftMessageIds = useSelector(draftMessageIdsSelector(projectId));
  const activeMessageId = useSelector<RootReducerState, MessageId>((state) =>
    getActiveMessageId(state, projectId)
  );
  return useMemo(
    () =>
      draftMessageIds.filter(
        (draftMessageId) => draftMessageId !== activeMessageId
      ),
    [draftMessageIds, activeMessageId]
  );
};

interface MessagePageProps {
  className?: string;
  projectId: ProjectId;
  backButton?: boolean;
  showAlwaysContent?: ReactNode;
}

export const MessagePage: React.FC<MessagePageProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    projectId: componentProjectId,
    backButton,
    showAlwaysContent,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme<PrioTheme>();
  const { pathname } = useLocation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const messageCenterProjectId = useSelector(getActiveProjectInMessageCenter);
  const projectId =
    componentProjectId === 'favorites'
      ? messageCenterProjectId
      : componentProjectId;

  const mailFolderId = useSelector<RootReducerState, MailFolderId>((state) =>
    getActiveMailFolderId(state, projectId)
  );

  const hasActiveMessage = useSelector<RootReducerState, boolean>(
    (state) => !!getActiveMessageId(state, projectId)
  );

  const activeDraftMessageId = useSelector<RootReducerState, string>((state) =>
    getActiveDraftMessageId(state, projectId)
  );

  const draftMessageIds = useDraftMessageIds(projectId);

  const specialMailFolders = useSelector<RootReducerState, SpecialMailFolders>(
    (state) => getSpecialMailFolders(state, projectId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnTabClick = (
    key: string,
    e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => {
    dispatch(
      setActiveDraftMessageId(projectId, key === 'activeMessage' ? null : key)
    );
  };

  const handleCloseDraft = (
    messageToDelete: Message,
    isInitial: boolean,
    nextMessageId: MessageId
  ) => {
    if (activeDraftMessageId === messageToDelete.id) {
      dispatch(setActiveDraftMessageId(projectId, nextMessageId));
    }
    if (isInitial) {
      if (specialMailFolders) {
        dispatch(
          deleteMessageSagaAction(
            projectId,
            specialMailFolders.draftFolder.id,
            [messageToDelete],
            messageToDelete.id === 'activeMessage' ? { navigate } : undefined,
            undefined,
            true
          )
        );
      }
    } else {
      dispatch(removeDraftMessage(projectId, messageToDelete.id));
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const messageId = !!pathname.match(/message\/(.*)\/details?$/)
      ? pathname.match(/message\/(.*)\/details?$/)[1]
      : null;
    dispatch(setMailListNavigationState(messageId, projectId));
    if (messageId) {
      dispatch(setActiveDraftMessageId(projectId, null));
    }
  }, [dispatch, projectId, mailFolderId, pathname]);
  //#endregion

  if (!showAlwaysContent && !hasActiveMessage && draftMessageIds.length === 0) {
    return (
      <div className={classes.root}>
        {showAlwaysContent ?? (
          <div className="prio-flex-center-center prio-flex-column">
            <Typography.Text>
              <Trans i18nKey={'mail:noMailSelected'} />
            </Typography.Text>
          </div>
        )}
      </div>
    );
  }

  return (
    <Tabs
      className={classNames(classes.root, className)}
      type="editable-card"
      onTabClick={handleOnTabClick}
      activeKey={activeDraftMessageId ?? 'activeMessage'}
      tabPosition="bottom"
      tabBarStyle={{
        backgroundColor: theme.old.palette.backgroundPalette.sub,
        margin: 0,
      }}
      hideAdd
    >
      {(showAlwaysContent || hasActiveMessage) && (
        <Tabs.TabPane
          key={'activeMessage'}
          tab={
            <MessageTabHeader
              projectId={projectId}
              mailFolderId={mailFolderId}
            />
          }
          closable={false}
        >
          {showAlwaysContent ??
            (hasActiveMessage ? (
              <MessageDetailsPage
                projectId={projectId}
                backButton={backButton}
                mailFolderId={mailFolderId}
              />
            ) : (
              <div className="prio-flex-center-center prio-flex-column">
                <Typography.Text>
                  <Trans i18nKey={'mail:noMailSelected'} />
                </Typography.Text>
              </div>
            ))}
        </Tabs.TabPane>
      )}
      {draftMessageIds.map((draftMessageId, index, array) => {
        const nextId =
          array.length > 1
            ? index === array.length - 1
              ? array[index - 1]
              : array[index + 1]
            : null;
        return (
          <Tabs.TabPane
            key={draftMessageId}
            tab={
              <MessageTabHeader
                projectId={projectId}
                messageId={draftMessageId}
                isDraft
              />
            }
            closeIcon={
              <CloseIcon
                projectId={projectId}
                draftMessageId={draftMessageId}
                onClick={(isInitial, messageToDelete) =>
                  handleCloseDraft(messageToDelete, isInitial, nextId)
                }
              />
            }
          >
            <DraftMessagePageWrapper
              draftMessageId={draftMessageId}
              projectId={projectId}
              mailFolderId={mailFolderId}
              nextDraftId={nextId}
            />
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};

export default MessagePage;

interface CloseIconProps {
  className?: string;
  projectId: ProjectId;
  draftMessageId: MessageId;
  onClick: (isIntitial: boolean, messageToDelete: Message) => void;
}

const CloseIcon: React.FC<CloseIconProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, projectId, draftMessageId, onClick } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isInitial = useSelector<RootReducerState, boolean>((state) =>
    getDraftMessageIsInitial(state, projectId, draftMessageId)
  );

  const draftMessage = useSelector<RootReducerState, Message>((state) =>
    getDraftMessage(state, projectId, draftMessageId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <FontAwesomeIcon
      className={className}
      icon={['fal', 'times']}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(isInitial, draftMessage);
      }}
    />
  );
};

interface MessageTabHeaderProps {
  projectId: ProjectId;
  mailFolderId?: MailFolderId;
  messageId?: MessageId;
  isDraft?: boolean;
}

const MessageTabHeader: React.FC<MessageTabHeaderProps> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId, isDraft, messageId, mailFolderId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeMessageId = useSelector<RootReducerState, string>((state) =>
    getActiveMessageId(state, projectId)
  );

  const message = useSelector<RootReducerState, Message>((state) =>
    isDraft
      ? getDraftMessage(state, projectId, messageId)
      : getMessage(state, projectId, activeMessageId) ??
        getSearchMessage(state, projectId, activeMessageId)
  );

  const specialMailFolders = useSelector<RootReducerState, SpecialMailFolders>(
    (state) => getSpecialMailFolders(state, projectId)
  );

  const mailFolder = useSelector<RootReducerState, MailFolder>((state) =>
    getMailFolder(
      state,
      mailFolderId === 'inbox'
        ? specialMailFolders?.inboxFolder?.id
        : mailFolderId,
      projectId
    )
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return message || mailFolder ? (
    <Typography.Text
      title={message?.subject ?? t('mail:messageDisplay.noSubject')}
      style={{
        width: '100%',
        color: 'inherit',
        flex: 1,
        maxWidth: 200,
        minWidth: 100,
      }}
      ellipsis
    >
      {message
        ? message?.subject ?? t('mail:messageDisplay.noSubject')
        : mailFolder?.displayName}
    </Typography.Text>
  ) : (
    <Skeleton
      className={classes.skeleton}
      title={{ width: '100%' }}
      paragraph={{ rows: 0 }}
    />
  );
};

interface DraftMessagePageWrapperProps {
  projectId: ProjectId;
  draftMessageId: MessageId;
  mailFolderId: MailFolderId;
  nextDraftId: MessageId;
}

const DraftMessagePageWrapper: React.FC<DraftMessagePageWrapperProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { projectId, draftMessageId, mailFolderId, nextDraftId } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const draftMessage = useSelector<RootReducerState, Message>((state) =>
    getDraftMessage(state, projectId, draftMessageId)
  );
  //#endregion

  return (
    <DraftMessagePage
      message={draftMessage}
      projectId={projectId}
      mailFolderId={mailFolderId}
      nextDraftId={nextDraftId}
      hideBackButton
    />
  );
};
