import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';
import { DriveItem, ListItemFieldColumnName } from '../../../models/Drive';
import { MessageAttachment } from '../../../models/Message';
import {
  OpenCurrentPreviewModalAction,
  OPEN_CURRENT_PREVIEW_MODAL,
  UPDATE_DRIVE_ITEM_META_DATA_PREVIEW_MODAL,
} from '../actions/previewModal';
import { DriveItemListItemId } from '../../../models/Types';

export interface OpenCurrentPreviewModal {
  visibility: boolean;
  driveItemId: string;
  groupId: string;
  driveItem: DriveItem;
  itemList: MessageAttachment[] | DriveItem[];
  pageNumber: number;
  messageId: string;
  projectId: string;
  isLoading: boolean;
  selectedDriveItems: DriveItem[];
}

const initialState: OpenCurrentPreviewModal = {
  visibility: false,
  driveItemId: '',
  groupId: '',
  driveItem: null,
  itemList: [],
  pageNumber: null,
  messageId: '',
  projectId: '',
  isLoading: true,
  selectedDriveItems: [],
};

const meta: Reducer<OpenCurrentPreviewModal, OpenCurrentPreviewModalAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_DRIVE_ITEM_META_DATA_PREVIEW_MODAL: {
      const update: Array<{
        listItemId: DriveItemListItemId;
        columnName: ListItemFieldColumnName;
        values: string[];
      }> = (action as any).update;
      return {
        ...state,
        itemList: update.reduce(
          (array, { listItemId, columnName, values }) => {
            const index = array.findIndex(
              (item) => item?.sharepointIds?.listItemId === listItemId
            );
            if (index > -1) {
              (array[index] as DriveItem).listItemFields[columnName] = values;
            }
            return array;
          },
          (state.itemList ?? []) as DriveItem[]
        ),
      };
    }
    case OPEN_CURRENT_PREVIEW_MODAL: {
      const { type, ...changed } = action;
      return {
        ...state,
        ...changed,
      };
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default meta;

export const getOpenCurrentPreviewModal = (state: OpenCurrentPreviewModal) =>
  state;
