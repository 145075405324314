import { ApiResult, apiUrl } from '../../../api';
import { HourlyRateSuggestion } from '../../../models/HourlyRate';
import {
  CreateProjectFileSystemStructure,
  ProjectFileSystemStructure,
} from '../../../models/Settings';
import {
  EMailSuffix,
  GlobalProjectSettingId,
  GlobalProjectSettingType,
  HourlyRateSuggestionId,
} from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import jsonMergePatch from 'json-merge-patch';
import { KilometerRateSuggestion } from '../../../models/KilometerRate';
import {
  Configuration,
  GlobalProjectSetting,
} from '../../../models/Configuration';
import { DocumentTemplateFindTag } from '../../../models/DocumentTemplate';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiFetchEmailSuffixes: () => Promise<
  ApiResult<EMailSuffix[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/setting/EmailSuffix`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectFileStructure: (
  signal?: AbortSignal
) => Promise<ApiResult<ProjectFileSystemStructure[]>> = async (signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ProjectFileSystemStructure`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentTemplatesForTags: (
  tags: string[],
  signal?: AbortSignal
) => Promise<ApiResult<DocumentTemplateFindTag[]>> = async (tags, signal) => {
  const queryString = encodeURIComponent(tags.join(','));
  const result = await fetchWithRetry(
    `${apiUrl}/document/DocumentTemplate/tag?tags=${queryString}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchHourlyRateSuggestions: (
  signal?: AbortSignal
) => Promise<ApiResult<HourlyRateSuggestion[]>> = async (signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateHourlyRateSuggestion: (
  suggestion: HourlyRateSuggestion
) => Promise<ApiResult<HourlyRateSuggestion>> = async (suggestion) => {
  const { hourlyRateSuggestionId, ...jsonData } = suggestion;

  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(jsonData),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateHourlyRateSuggestion: (
  updatedHourlyRateSuggestion: HourlyRateSuggestion,
  originalRateSuggestion: HourlyRateSuggestion
) => Promise<ApiResult<HourlyRateSuggestion>> = async (
  updatedHourlyRateSuggestion,
  originalRateSuggestion
) => {
  const patch = jsonMergePatch.generate(
    originalRateSuggestion,
    updatedHourlyRateSuggestion
  );
  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion/${updatedHourlyRateSuggestion.hourlyRateSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...patch,
        rowVersion: originalRateSuggestion.rowVersion,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiDeleteHourlyRateSuggestion: (
  hourlyRateSuggestionId: HourlyRateSuggestionId
) => Promise<ApiResult<void>> = async (hourlyRateSuggestionId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/HourlyRateSuggestion/${hourlyRateSuggestionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );
  return {
    result,
  };
};

export const apiFetchKilometerRateSuggestion: (
  signal?: AbortSignal
) => Promise<ApiResult<KilometerRateSuggestion>> = async (signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/KilometerRateSuggestion`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSetKilometerRateSuggestion: (
  suggestion: KilometerRateSuggestion
) => Promise<ApiResult<KilometerRateSuggestion>> = async (suggestion) => {
  const { kilometerRateSuggestionId, ...jsonData } = suggestion;

  const result = await fetchWithRetry(
    `${apiUrl}/setting/KilometerRateSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(jsonData),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchConfigurations: (
  signal?: AbortSignal
) => Promise<ApiResult<Configuration[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/setting/Configuration`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    signal,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  signal?: AbortSignal
) => Promise<ApiResult<GlobalProjectSetting[]>> = async (type, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${type}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateConfiguration: (
  config: Configuration
) => Promise<ApiResult<Configuration>> = async (config) => {
  const result = await fetchWithRetry(`${apiUrl}/setting/Configuration`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(config),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  setting: GlobalProjectSetting
) => Promise<ApiResult<GlobalProjectSetting>> = async (type, setting) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSetDefaultGlobalProjectSetting: (
  settingId: GlobalProjectSettingId
) => Promise<ApiResult<GlobalProjectSetting>> = async (settingId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${settingId}/setDefault`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'PUT',
    }
  );
  return {
    result,
  };
};

export const apiArchiveGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  settingId: GlobalProjectSettingId
) => Promise<ApiResult<boolean>> = async (type, settingId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${settingId}/${type}/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  const data = null;
  return {
    result,
    data,
  };
};

export const apiUpdateConfiguration: (
  updatedConfiguration: Configuration,
  originalConfiguration: Configuration
) => Promise<ApiResult<Configuration>> = async (
  updatedConfiguration,
  originalConfiguration
) => {
  const patch = jsonMergePatch.generate(
    originalConfiguration,
    updatedConfiguration
  );
  const result = await fetchWithRetry(
    `${apiUrl}/setting/Configuration/${updatedConfiguration.configurationId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...patch,
        rowVersion: originalConfiguration.rowVersion,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateGlobalProjectSetting: (
  type: GlobalProjectSettingType,
  setting: GlobalProjectSetting
) => Promise<ApiResult<GlobalProjectSetting>> = async (type, setting) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/globalProjectSetting/${type}/${setting.globalProjectSettingId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectFileSystemStructure: (
  projectFileSystemStructure: CreateProjectFileSystemStructure
) => Promise<ApiResult<ProjectFileSystemStructure>> = async (
  projectFileSystemStructure
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/ProjectFileSystemStructure`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(projectFileSystemStructure),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchConfigurationByKey: (
  keyName: string
) => Promise<ApiResult<Configuration>> = async (keyName) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/configuration/key/${keyName}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
