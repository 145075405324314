import React, { MouseEventHandler, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import { TimelineGroup, TimelineItem as TimelineItemModel } from './types';
import moment from 'moment';
import { getSnapPixelFromDelta } from './utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';
//@typescript-eslint/no-unused-vars
import { useTimelineItemContext } from './TimelineItemProvider';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'absolute',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    '&[isresizing=true] $content $grabber': {
      visibility: 'visible',
    },
    '&:hover $content $grabber': {
      visibility: 'visible',
    },
    '&[isresizing=true] $content': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.base,
    },
    '&[isdragging=true] $content': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.base,
    },
  },
  rootDisabled: {
    '&:hover $content $grabber': {
      visibility: 'hidden',
    },
    '& $content': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.base,
    },
  },
  content: {
    position: 'relative',
    flex: 1,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.base,
    margin: `${theme.old.spacing.baseSpacing}px 0`,
    color: theme.old.typography.colors.inverted,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'background-color 200ms',
  },
  grabber: {
    height: '100%',
    visibility: 'hidden',
    zIndex: 10,
  },
  grabberIcon: {
    height: `calc(100% - ${theme.old.spacing.unit(2)}px)`,
    margin: theme.old.spacing.unit(1),
    borderLeft: `4px double ${theme.old.typography.colors.inverted}`,
  },
  title: {
    flex: 1,
    overflow: 'hidden',
  },
  shallowPopover: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: `${theme.old.spacing.baseSpacing}px 0`,
    color: theme.old.typography.colors.inverted,
  },
  shallowPopoverContent: {
    height: '100%',
    width: '100%',
    position: 'relative',
    zIndex: 5,
  },
  popover: {
    position: 'absolute',
    cursor: 'pointer',
    minWidth: 32,
    minHeight: 32,
    backgroundColor: theme.old.palette.backgroundPalette.hover.base,
    boxShadow: `${theme.old.palette.boxShadow.regular}, 0 10px 10px rgb(0 0 0 / 25%)`,
    visibility: 'hidden',
    borderRadius: theme.old.borders.radius,
    display: 'flex',
    alignItems: 'center',
    zIndex: 9,
  },
  popoverTriangle: {
    position: 'absolute',
    left: 'calc(50% - 8px)',
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
  },
}));

const isTemporaryId = (id: string) => {
  return id ? id.startsWith('temp-') : false;
};

interface TimelineItemProps {
  className?: string;
  item: TimelineItemModel;
  group: TimelineGroup;
  rowOffset: number;
  pixelsPerMs: number;
  itemHeight: number;
  onClick?: (item: TimelineItemModel, group: TimelineGroup) => void;
  gridSnapInMilliseconds?: number;
  maxRowOffset: number;
}

export const TimelineItem: React.FC<TimelineItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    item,
    group,
    rowOffset,
    pixelsPerMs,
    itemHeight,
    onClick,
    gridSnapInMilliseconds,
    maxRowOffset,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const {
    startTime,
    disableResize,
    itemStyle,
    itemRenderer,
    popoverRenderer,
    onPopoverClick,
  } = useTimelineItemContext();

  const contentStyle = useMemo(() => {
    if (itemStyle) {
      return itemStyle(group, item);
    }
    return {};
  }, [item, group, itemStyle]);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const startDate = moment(item.startDateTime);
  const endDate = moment(item.endDateTime);

  const itemOffsetMs = startDate.diff(startTime, 'milliseconds');
  const itemDurationMs = endDate.diff(startDate, 'milliseconds');

  const ref = useRef<HTMLDivElement>(null);
  const shallowRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handlePopoverVisibility = (visibility: 'hidden' | 'visible') => {
    try {
      const popover = popoverRef.current;
      const element = ref.current;
      if (element && popover) {
        popover.style.visibility = visibility;
        if (visibility === 'hidden') {
          element.style.zIndex = 'unset';
        } else {
          element.style.zIndex = `${(maxRowOffset - rowOffset + 1) * 10 + 1}`;
        }
      }
    } catch (e) {}
  };

  const setBottomOrTop = () => {
    try {
      const gridElement = document
        .getElementsByClassName('prio-timeline-body')
        ?.item(0) as HTMLDivElement;
      const shallowElement = shallowRef.current;
      const popoverElement = popoverRef.current;
      if (gridElement && shallowElement && popoverElement) {
        const triangleElement = popoverElement
          .getElementsByClassName(classes.popoverTriangle)
          ?.item(0) as HTMLDivElement;
        if (triangleElement) {
          const { y: elementY } = shallowElement?.getBoundingClientRect();
          const { y: gridY } = gridElement?.getBoundingClientRect();
          const { height } = popoverElement?.getBoundingClientRect();

          if (elementY - gridY >= height + 8) {
            popoverElement.style.top = `-${height + 4}px`;
            popoverElement.style.bottom = 'unset';
            triangleElement.style.borderTop = `8px solid ${theme.old.palette.backgroundPalette.hover.base}`;
            triangleElement.style.borderBottom = 'unset';
            triangleElement.style.top = 'unset';
            triangleElement.style.bottom = '-8px';
          } else {
            popoverElement.style.top = 'unset';
            popoverElement.style.bottom = `-${height + 4}px`;
            triangleElement.style.borderTop = 'unset';
            triangleElement.style.borderBottom = `8px solid ${theme.old.palette.backgroundPalette.hover.base}`;
            triangleElement.style.top = '-8px';
            triangleElement.style.bottom = 'unset';
            popoverElement.style.boxShadow = `${theme.old.palette.boxShadow.regular}, 0 -10px 10px rgb(0 0 0 / 25%)`;
          }
        }
      }
    } catch (e) {}
  };

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    try {
      const popover = popoverRef.current;
      const shallowElement = shallowRef.current;
      if (shallowElement && popover) {
        const { pageX } = event;
        const { x, width: shallowWidth } =
          shallowElement?.getBoundingClientRect();
        const { width } = popover?.getBoundingClientRect();

        if (x <= pageX && x + shallowWidth >= pageX) {
          const newLeft =
            getSnapPixelFromDelta(
              pageX - x,
              pixelsPerMs,
              gridSnapInMilliseconds
            ) -
            width / 2;
          popover.style.left = `${newLeft}px`;
        }
      }
    } catch (e) {}
  };

  const handleOnPopoverClick: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (onPopoverClick) {
      onPopoverClick(item, group, getPopoverPositionInMS());
    }
  };

  const getPopoverPositionInMS = () => {
    try {
      const shallowElement = shallowRef.current;
      const popoverElement = popoverRef.current;
      if (shallowElement && popoverElement) {
        const { x: shallowX } = shallowElement?.getBoundingClientRect();
        const { x: popoverX, width } = popoverElement?.getBoundingClientRect();
        const position = getSnapPixelFromDelta(
          popoverX - shallowX + width / 2,
          pixelsPerMs,
          gridSnapInMilliseconds
        );
        return position * (1 / pixelsPerMs);
      }
    } catch (e) {}
    return 0;
  };
  //#endregion

  //#region ------------------------------ Components
  const popover = useMemo(() => {
    if (popoverRenderer) {
      return popoverRenderer(item, group);
    }
    return null;
  }, [item, group, popoverRenderer]);
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames('prio-timeline-item', classes.root, className, {
        [classes.rootDisabled]:
          disableResize || isTemporaryId(item.id) || item.disabled,
      })}
      style={{
        top: itemHeight * rowOffset,
        left: itemOffsetMs * pixelsPerMs,
        width: itemDurationMs * pixelsPerMs,
        height: itemHeight,
      }}
      data-item-id={item.id}
      data-group-id={item.groupId}
      is-creating={
        isTemporaryId(item.id) ? `${isTemporaryId(item.id)}` : undefined
      }
      update-disabled={`${item.disabled ?? false}`}
      onClick={() => {
        if (onClick) {
          onClick(item, group);
        }
      }}
      ref={ref}
    >
      {!isTemporaryId(item.id) && popover && (
        <div className={classes.shallowPopover}>
          <div
            className={classes.shallowPopoverContent}
            onMouseEnter={() => {
              if (!isTemporaryId(item.id) && popover) {
                handlePopoverVisibility('visible');
                setBottomOrTop();
              }
            }}
            onMouseMove={
              !isTemporaryId(item.id) && popover ? handleMouseMove : undefined
            }
            onMouseLeave={() => {
              if (!isTemporaryId(item.id) && popover) {
                handlePopoverVisibility('hidden');
              }
            }}
            ref={shallowRef}
          >
            <div
              className={classNames(
                classes.popover,
                'prio-timelinine-item-popover'
              )}
              ref={popoverRef}
              onClick={handleOnPopoverClick}
            >
              {popover}
              <div className={classes.popoverTriangle} />
            </div>
          </div>
        </div>
      )}
      <div className={classes.content} style={contentStyle}>
        <div
          className={classes.grabber}
          style={{
            width: gridSnapInMilliseconds * pixelsPerMs,
          }}
        >
          <div
            className={classes.grabberIcon}
            style={{
              marginLeft: (gridSnapInMilliseconds * pixelsPerMs) / 2 - 2,
              marginRight: (gridSnapInMilliseconds * pixelsPerMs) / 2 - 2,
            }}
          />
        </div>
        {itemRenderer ? (
          itemRenderer(group, item, pixelsPerMs, gridSnapInMilliseconds)
        ) : (
          <div className={classes.title}>{item.title}</div>
        )}
        <div
          className={classes.grabber}
          style={{
            width: gridSnapInMilliseconds * pixelsPerMs,
          }}
        >
          <div
            className={classes.grabberIcon}
            style={{
              marginLeft: (gridSnapInMilliseconds * pixelsPerMs) / 2 - 2,
              marginRight: (gridSnapInMilliseconds * pixelsPerMs) / 2 - 2,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
