import React from 'react';
import { makePrioStyles } from '../../../../theme/utils';
import { TimeAndLeaveCalendarEvent } from '../../selectors';
import { createSelector } from 'reselect';
import {
  RootReducerState,
  getAbsenceProposalMe,
  getTimeKeepingDayByIdState,
  getTimeRecordsByIdState,
} from '../../../../apps/main/rootReducer';
import { TimeRecordsByIdState } from '../../../timeRecords/reducers/timeRecords';
import { TimeKeepingByIdState } from '../../../timeKeeping/reducers/timeKeepingDaysMe';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import { CustomCalendarEvent } from '../../../../models/Calendar';
import i18n from '../../../../i18n';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyles = makePrioStyles((theme) => ({
  root: {
    color: theme.old.typography.colors.inverted,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: theme.old.typography.fontSize.small,
  },
}));

const roundToTwo = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const generateMultipleDaysAbsenceTitle = (absenceProposal: AbsenceProposal) => {
  if (moment(absenceProposal.from).isSame(absenceProposal.to, 'year'))
    return `${i18n.t(
      `absences:form.absenceTypes.${absenceProposal?.absenceType}`
    )}, ${moment(absenceProposal.from).format('DD.MM.')}${
      absenceProposal.firstIsHalfDay === true
        ? ` (${i18n.t('timeAndLeaveManagement:calendar.events.halfDay')})`
        : ''
    } - ${moment(absenceProposal.to).format('DD.MM.YYYY')}${
      absenceProposal.lastIsHalfDay === true
        ? ` (${i18n.t('timeAndLeaveManagement:calendar.events.halfDay')})`
        : ''
    }`;

  return `${i18n.t(
    `absences:form.absenceTypes.${absenceProposal?.absenceType}`
  )}, ${moment(absenceProposal.from).format('DD.MM.YYYY')}${
    absenceProposal.firstIsHalfDay === true
      ? ` (${i18n.t('timeAndLeaveManagement:calendar.events.halfDay')})`
      : ''
  } - ${moment(absenceProposal.to).format('DD.MM.YYYY')}${
    absenceProposal.lastIsHalfDay === true
      ? ` (${i18n.t('timeAndLeaveManagement:calendar.events.halfDay')})`
      : ''
  }`;
};

const eventItemsSelector = (
  isAbsence: boolean,
  events: CustomCalendarEvent[]
) =>
  createSelector<
    [
      (state: RootReducerState) => TimeRecordsByIdState,
      (state: RootReducerState) => TimeKeepingByIdState,
      (state: RootReducerState) => AbsenceProposal,
    ],
    string | { timeRecordSum: number; timeKeepingSum: number }
  >(
    getTimeRecordsByIdState,
    getTimeKeepingDayByIdState,
    (state) => (isAbsence ? getAbsenceProposalMe(state, events[0].id) : null),
    (timeRecordById, timeKeepingById, absenceProposal) => {
      if (isAbsence) {
        if (moment(absenceProposal.from).isSame(absenceProposal.to, 'day')) {
          return `${i18n.t(
            `absences:form.absenceTypes.${absenceProposal?.absenceType}`
          )}${
            absenceProposal.firstIsHalfDay === true
              ? ` (${i18n.t(
                  'timeAndLeaveManagement:calendar.events.firstHalfOfDay'
                )})`
              : absenceProposal.lastIsHalfDay === true
              ? ` (${i18n.t(
                  'timeAndLeaveManagement:calendar.events.secondHalfOfDay'
                )})`
              : ''
          }`;
        }

        return generateMultipleDaysAbsenceTitle(absenceProposal);
      }

      return events.reduce(
        (acc, event) => {
          const timeRecord = timeRecordById[event.id];
          const timeKeeping = timeKeepingById[event.id];

          if (timeRecord) {
            acc.timeRecordSum += roundToTwo(
              timeRecord.timeRecordEntries.reduce(
                (acc, { startTime, endTime }) => {
                  return (
                    acc + moment(endTime).diff(moment(startTime), 'minutes')
                  );
                },
                0
              ) / 60
            );
          }

          if (timeKeeping) {
            acc.timeKeepingSum += roundToTwo(
              timeKeeping.timeKeepingEntries.reduce(
                (acc, { startTime, endTime }) => {
                  return (
                    acc + moment(endTime).diff(moment(startTime), 'minutes')
                  );
                },
                0
              ) / 60
            );
          }

          return acc;
        },
        { timeRecordSum: 0, timeKeepingSum: 0 }
      );
    }
  );

interface MonthEventComponentProps {
  event: TimeAndLeaveCalendarEvent;
}

export const MonthEventComponent: React.FC<MonthEventComponentProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { event } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const type = event?.resources[0]?.resources?.type;

  const title = useSelector(
    eventItemsSelector(type === 'absence', event?.resources || [])
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (typeof title === 'string') {
    return (
      <div className={classes.root} title={title}>
        {title}
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      title={`${
        title.timeKeepingSum > 0
          ? `${title.timeKeepingSum} h ${t('timeKeeping:timeKeeping')}${
              title.timeRecordSum > 0 ? ', ' : ''
            }`
          : ''
      }${
        title.timeRecordSum > 0
          ? `${title.timeRecordSum} h ${t('timeRecords:timeRecords')}`
          : ''
      }`}
    >
      <Flex.Row alignItems="center" childrenGap={theme.old.spacing.baseSpacing}>
        {title.timeKeepingSum > 0 && (
          <Flex.Row
            alignItems="center"
            childrenGap={theme.old.spacing.baseSpacing}
          >
            <FontAwesomeIcon icon={['fal', 'business-time']} />
            <div>{`${title.timeKeepingSum} h`}</div>
          </Flex.Row>
        )}
        {title.timeRecordSum > 0 && (
          <Flex.Row
            alignItems="center"
            childrenGap={theme.old.spacing.baseSpacing}
          >
            <FontAwesomeIcon icon={['far', 'bars-progress']} />
            <div>{`${title.timeRecordSum} h`}</div>
          </Flex.Row>
        )}
      </Flex.Row>
    </div>
  );
};

export default MonthEventComponent;
