import React, { useCallback, useMemo } from 'react';
import Flex from '../../../components/Flex';
import {
  MessageAttachment,
  MessageDriveItemMap,
} from '../../../models/Message';
import { makePrioStyles } from '../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Dropdown, Menu, Tooltip } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { formatHumanFileSize } from '../../../util';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDrag } from 'react-dnd';
import { DND_TYPE_EMAIL_ATTACHMENT } from '../../../dnd/types';
import { MessageId, ProjectId } from '../../../models/Types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  checkIfLink,
  colorForAttachmentIcon,
  iconForAttachment,
} from '../../documents/util';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPreviewModalVisibility } from '../../documents/actions/previewModal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import classNames from 'classnames';
import { getMailSettings } from '../../../apps/main/rootReducer';
import { useDriveItemActions } from '../../documents/hooks/useDriveItemActions';
import {
  openWidgetArea,
  setActiveProjectWidgetBar,
} from '../../widgetArea/actions';
import { updateDriveItemPath } from '../../documents/actions/lastOpenDriveItemFolder';
import useProjectsContext from '../../projects/hooks/useProjectsContext';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    fontSize: theme.old.components.mailListItem.fontSize,
    alignItems: 'center',
    width: '100%',
    '& > button': {
      visibility: 'hidden',
    },
    '&:hover > button': {
      visibility: 'visible',
    },
    '& .ant-checkbox-wrapper': {
      fontSize: theme.old.components.mailListItem.fontSize,
    },
  },
  rootDropdown: {
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  selectableItem: {
    marginLeft: 12,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    '& > span:last-child': {
      width: '100%',
    },
    '& > span.ant-checkbox': {
      fontSize: theme.old.typography.fontSize.label,
      display: 'flex',
      alignItems: 'center',
      width: '16px',
      marginBottom: '5px',
    },
    '& > span': {
      fontSize: theme.old.typography.fontSize.label,
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100%)',
    },
  },
  selectableItemCompressedView: {
    marginLeft: 8,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    overflow: 'hidden',
    '& > *:not(:first-child)': {
      marginLeft: 8,
    },
  },
  iconSize: {
    '&.fa-layers': {
      maxWidth: '1em',
      width: '100%',
    },
    '& > svg:nth-child(1)': {
      visibility: 'visible',
    },
    '& > svg:nth-child(2)': {
      visibility: 'hidden',
    },
    '&:hover > svg:nth-child(1)': {
      visibility: 'hidden',
    },
    '&:hover > svg:nth-child(2)': {
      visibility: 'visible',
      cursor: 'pointer',
    },
  },
  attachmentName: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface SelectableMessageAttachmentItemProps {
  messageAttachments: MessageAttachment[];
  messageAttachment: MessageAttachment;
  onDownloadAttachment?: (messageAttachment: MessageAttachment) => void;
  onSelectionChange?: (e: CheckboxChangeEvent) => void;
  messageId: MessageId;
  selected: boolean;
  selectedAttachments: MessageAttachment[];
  projectId: ProjectId;
  isDropdown: boolean;
  onDeleteAttachment?: (attachmentId: string) => Promise<void>;
  receivedMessage: boolean;
  savedAttachmentsMetadata?: MessageDriveItemMap[];
}

export interface DraggableMessageAttachment {
  messageId: MessageId;
  attachment: MessageAttachment;
}

export const SelectableMessageAttachmentItem: React.FC<
  SelectableMessageAttachmentItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const dispatch = useDispatch();
  const {
    messageAttachment,
    messageAttachments,
    onDownloadAttachment,
    onSelectionChange,
    messageId,
    selected,
    projectId,
    selectedAttachments,
    isDropdown,
    onDeleteAttachment,
    receivedMessage,
    savedAttachmentsMetadata = [],
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const draggableMessageAttachment: DraggableMessageAttachment = {
    messageId,
    attachment: messageAttachment,
  };

  const [{ opacity }, dragRef, preview] = useDrag({
    type: DND_TYPE_EMAIL_ATTACHMENT,
    item: {
      draggableMessageAttachment,
      selectedAttachments: selected ? selectedAttachments : undefined,
      projectId,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  const { openLinkInBrowser } = useDriveItemActions(null, null);

  const mailSettings = useSelector(getMailSettings);

  const { getProjectByProperty } = useProjectsContext();
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const onDownload = () => {
    if (onDownloadAttachment) {
      onDownloadAttachment(messageAttachment);
    }
  };

  const onDelete = () => {
    if (onDeleteAttachment) {
      onDeleteAttachment(messageAttachment.id);
    }
  };

  const handleOnEyeClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    if (checkIfLink(messageAttachment.name)) {
      openLinkInBrowser(messageAttachment.name);
    } else {
      const index = messageAttachments.findIndex(
        (element) => element.id === messageAttachment.id
      );

      dispatch(
        setCurrentPreviewModalVisibility(
          true,
          null,
          null,
          null,
          messageAttachments,
          index,
          messageId,
          projectId,
          true,
          null
        )
      );
    }
  };

  const openParentFolder = useCallback(
    (item: MessageDriveItemMap, projectId: ProjectId) => {
      if (projectId !== 'me') {
        dispatch(openWidgetArea('documents'));
        dispatch(setActiveProjectWidgetBar(projectId));
        dispatch(
          updateDriveItemPath(projectId, {
            documentsWidget: item.parentReferenceId,
          })
        );
      }
    },
    [dispatch]
  );
  //#endregion

  //#region ------------------------------ Components
  const savedAttachmentsMetadataMenuItems = useMemo(() => {
    return savedAttachmentsMetadata.map((item) => {
      const project = getProjectByProperty('groupId', item.groupId);
      if (!project) {
        return null;
      }
      return (
        <Menu.Item
          key={item.driveItemId}
          onClick={({ domEvent }) => {
            domEvent.stopPropagation();
            openParentFolder(item, project.projectId);
          }}
        >
          {`${project.name}${item.parentReferencePath
            .replace(/^\/drive\/root:/, '')
            .replace(/\//g, ' / ')}`}
        </Menu.Item>
      );
    });
  }, [savedAttachmentsMetadata, openParentFolder, getProjectByProperty]);
  //#endregion

  preview(getEmptyImage());

  return (
    <div ref={dragRef} style={{ opacity }}>
      <Flex.Row
        childrenGap={theme.old.spacing.unit(1)}
        className={isDropdown ? classes.rootDropdown : classes.root}
        alignItems="center"
      >
        {!isDropdown && (
          <Checkbox
            onChange={onSelectionChange}
            id={messageAttachment.id}
            className={classNames(classes.selectableItem, {
              [classes.selectableItemCompressedView]:
                mailSettings.mailListSpacing === 'tight',
            })}
            checked={selected}
          />
        )}
        <div className={classes.content}>
          <span className={classNames('fa-layers', classes.iconSize)}>
            <FontAwesomeIcon
              icon={['fas', iconForAttachment(messageAttachment)]}
              color={colorForAttachmentIcon(messageAttachment.contentType)}
            />
            <FontAwesomeIcon icon={['fal', 'eye']} onClick={handleOnEyeClick} />
          </span>
          <Tooltip
            title={messageAttachment.name}
            className={classes.attachmentName}
            placement={'topLeft'}
          >
            {messageAttachment.name}
          </Tooltip>
          <div>{formatHumanFileSize(messageAttachment.size)}</div>
          <Flex.Row alignItems="center">
            {!receivedMessage && !messageAttachment.isInline && (
              <Button
                type="link"
                size="small"
                onClick={onDelete}
                iconProp={['fal', 'trash']}
                style={{ fontSize: '14px' }}
              />
            )}
            {savedAttachmentsMetadataMenuItems.length > 0 && (
              <Dropdown
                overlay={<Menu>{savedAttachmentsMetadataMenuItems}</Menu>}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  type="link"
                  onClick={(event) => event.stopPropagation()}
                  iconProp={['fal', 'folder-bookmark']}
                  style={{ fontSize: '14px' }}
                  size="small"
                />
              </Dropdown>
            )}
            <Button
              type="link"
              onClick={onDownload}
              iconProp={['fal', 'arrow-to-bottom']}
              style={{ fontSize: '14px', marginLeft: '0px' }}
              size="small"
            />
          </Flex.Row>
        </div>
      </Flex.Row>
    </div>
  );
};

export default SelectableMessageAttachmentItem;
